export default {
	//法语
	header: {
		menu: ['Page d`accueil', 'Transactions', 'Transactions IPO', 'Intérêt de gage','Le contrat'],
		assets: 'Total des actifs',
		actions: ['Modifier le mot de passe de connexion', 'Définir le mot de passe de transaction', 'Quitter',
			'Certification du vrai nom',
			'Enregistrement rempli de monnaie', 'Enregistrement de la monnaie','Enregistrement des transactions'
		]
	},
	footer: {
		text: 'Actifs numériques mondiaux de haute qualité',
		menu: ['À propos de nous', 'Contrat d`utilisation', 'Conditions d`utilisation', 'Conditions de confidentialité']
	},
	common: {
		confirm: 'OK',
		cancel: 'Annuler',
		copy: 'Copier',
		copysuccess: 'Copier avec succès !',
		time:'Le temps'
	},
	authentication:{
		pagename:'Authentification par nom réel',
		entername:'Veuillez entrer votre nom',
		enteridcard:'Veuillez entrer votre carte d`identité',
	},
	topUpsorder:{
		status:['Rappel en cours','Recharge','Succès','Échouer']
	},
	index: {
		trade: 'Aller au commerce',
		bantitle: 'Plateforme de trading de crypto-monnaie digne de confiance',
		bantext: 'Mettre tout en œuvre pour garantir la sécurité des utilisateurs avec des protocoles stricts et des mesures techniques de pointe',
		marketqs: 'Tendances du marché',
		pro: 'Produits et services',
		protext: 'Fournir des millions d`utilisateurs à travers le monde Sûr et des services fiables de négociation d`actifs numériques et de gestion d`actifs contribuent à l`évolution de l`économie de la blockchain et à remodeler le nouveau modèle écologique de l`industrie',
		pro1title: 'disposition globale',
		pro1text: 'des centres de services locaux ont été établis dans de nombreux pays du monde, intégrant diverses formes commerciales dans Integrated',
		pro2title: 'sécurité du système',
		pro2text: 'architecture distribuée professionnelle et système d`attaque anti-DoS, séparation des portefeuilles chauds et froids, protection multidimensionnelle des actifs de l`utilisateur',
		pro3title: 'expérience de transaction fluide',
		pro3text: 'moteur de transaction correspondant à haute efficacité pour assurer une expérience de transaction fluide, chaque seconde Traitez des millions de commandes et payez pour le bon fonctionnement de plus de 20 millions d`utilisateurs en ligne'
	},
	newTradeIndex: {
		status: ['Démarrage en attente', 'En cours', 'Clôturé'],
		buy: 'Commande',
		issue: 'Circulation',
		bi: 'Devise',
		begintime: 'Heure de début',
		endtime: 'Heure de fin',
		buynumber: 'Quantité de commande',
		enternumber: 'Veuillez entrer la quantité de la commande'
	},
	pledge: {
		record: 'Enregistrement de l`engagement',
		pledge: 'Engagement',
		min: 'Quantité minimale de l`engagement',
		day: 'Cycle de l`engagement',
		rate: 'Intérêt quotidien',
		num: 'Quantité de l`engagement',
		enternum: 'Veuillez entrer la quantité de l`engagement',
		historytable: ['Numéro de commande', 'Devise', 'Montant de l`engagement', 'total interest', 'locking time',
			'expiration time', 'status'
		],
		historystatus: ['holding', 'completed', 'closed']
	},
	contract:{
		sj:'Prix du marché',
		xj:'Prix limité',
		bibi:'Compte coin coin',
		zy:'Fin de la perfection',
		zs:'Stop perte',
		buy:'Acheter pour augmenter',
		sell:'Vendre à découvert',
		set:'Paramètres',
		yjpc:'Fermer la position en un clic',
		surepc:'Est - ce sûr de fermer la position?',
		yjpcList:['Fermer toutes les positions','Simple plat','Seule la feuille vide'],
		setzyzs:'Définir un stop / stop loss',
		nobanlance:'Solde insuffisant'
	},
	trade: {
		name: 'name',
		newprice: 'latest price',
		range: 'change rate',
		biaccount: 'currency account',
		risk: 'Risk rate',
		risktip: 'Le taux de risque est un indicateur à mesurer le risque du compte La formule de calcul est la suivante : marge de position ✖️30%/valeur nette du compte Lorsque le taux de risque est de 100%, toutes les positions du compte seront forcées de clôturer au prix du marché',
		canuse: 'Marge disponible',
		nouse: 'Profit non réalisé et perte',
		ccmoney: 'Marge de position',
		djmoney: 'Marge gelée',
		tradeing: 'Trading',
		highprice: 'Prix le plus élevé',
		lowprice: 'Prix le plus bas',
		volnum: 'Volume de trading',
		kcprice: 'Prix d`ouverture',
		kcplaceholder: 'Position ouverte au meilleur prix du marché',
		num: 'Nombre de mains de Trading',
		enternum: 'Veuillez entrer la quantité',
		banlance: 'disponible',
		buy: 'achat',
		sell: 'vente',
		index: 'numéro de série',
		price: 'prix',
		record: 'historique des transactions',
		table: ['devise', 'sens d`achat et de vente', 'prix de règlement', 'quantité', 'temps d`achat',
			'temps de vente', 'profit et perte','Prix d`achat','Les derniers prix','Multiplicateur de levier'
		],
		type: '> Direction',
		tradesuccess: 'Commande réussie',
		doprice: 'Prix d`exécution',
		income: 'Bénéfice',
		bi: 'Devise',
		continue: 'Continuer à passer une commande',
		pc:'Fermer la position',
		action:'Opérations',
		jye:'Volume des transactions',
		sjjy:'Transactions sur le marché',
		xjjy:'Trading à prix limité',
		surepc:'Sûr de fermer la position?'
	},
	tradeRecord:{
		yl:'Rentable',
		kcj:'Prix d`ouverture',
		zyj:'Prix de clôture',
		zhsl:'Nombre de plis',
		dqj:'Prix actuel',
		zsj:'Prix stop loss',
		kybzj:'Marge disponible',
		bzj:'Dépôt de garantie',
		sxf:'Frais de traitement',
		gyf:'Frais de nuit',
		kcsj:'Heure d`ouverture des positions'
	},
	assets: {
		account: 'Devise du compte',
		asset: 'Actif total',
		topup: 'Dépôt',
		withdraw: 'Retrait',
		table: ['Devise', 'Disponible', 'Geler', 'Conversion']
	},
	topup: {
		canuse: 'Solde disponible',
		selbi: 'Veuillez sélectionner la devise',
		binet: 'Réseau de dépôt',
		biaddress: 'Adresse de dépôt'
	},
	bringup: {
		min: 'Montant minimum de retrait',
		address: 'Adresse de retrait',
		enteraddress: 'Veuillez saisir l`adresse de retrait',
		num: 'Montant du retrait',
		enternum: 'Veuillez saisir le montant du retrait',
		pwd: 'Mot de passe de la transaction',
		enterpassword: 'Veuillez saisir le mot de passe de la transaction',
		nosettip: 'Vous n`avez pas défini le mot de passe de la transaction, allez dans Paramètres ?',
		tip: 'Rappel',
		set: 'Allez dans Paramètres'
	},
	password: {
		loginpwd: 'Mot de passe de connexion',
		paypwd: 'Mot de passe de transaction',
		old: 'Veuillez saisir l`ancien mot de passe',
		new: 'Veuillez saisir le nouveau mot de passe',
		sure: 'Confirmez le nouveau mot de passe',
		setsuccess: 'Le mot de passe est défini avec succès !'
	},
	login: {
		welcome: 'Bienvenue pour vous connecter',
		title: 'Connexion',
		enterphone: 'Veuillez saisir le numéro de téléphone mobile',
		enterpassword: 'Veuillez saisir le mot de passe',
		loginsuccess: 'Connexion réussie'
	},
	register: {
		nav: ['Enregistrement du téléphone portable', 'Enregistrement par e-mail'],
		title: 'Enregistrement',
		quick: 'Enregistrement rapide',
		entereamil: 'Veuillez saisir l`adresse e-mail',
		surepwd: 'Veuillez confirmer le mot de passe',
		entercode: 'Veuillez entrer le code de vérification',
		nosame: 'les deux mots de passe sont incohérents, veuillez les saisir à nouveau !',
		registersuccess: 'l`enregistrement a réussi, veuillez vous connecter !',
		get:'Obtenir',
		enterpaypass:'Veuillez entrer le mot de passe de paiement',
		enterinvitecode:'Veuillez entrer le Code d`invitation'
	}
}
