<template>
  <div class="about">
    <v-header />
    <div class="content-box">
      <v-tags></v-tags>
      <div class="content">
        <router-view :key="key" v-slot="{ Component }">
          <transition
            name="move"
            mode="out-in"
            enter-active-class="animated slideInDown"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <el-popover
      placement="left"
      width="60px"
      trigger="hover">
      <span style="color: #333;">联系客服</span>
      <template  v-slot:reference>
        <div class="connect" @click="toKefu">
          <i class="el-icon-phone-outline"></i>
        </div>
      </template>
    </el-popover>
    <v-footer />
  </div>
</template>
<script>
import vHeader from "../components/Header";
import vFooter from "../components/Footer";
export default {
  components: {
    vHeader,
    vFooter,
  },
  computed: {
    key() {
      return this.$route.query.id || this.$route.query.type;
    },
  },
  methods: {
    toKefu() {
      if(!this.config.kefu) {
        this.$message.error('暂未开放');
        return;
      }
      window.open(this.config.kefu);
    }
  }
};
</script>
<style>
.content-box {
  margin-top: 56px;
}
.connect {
  position: fixed;
  right: 20px;
  bottom: 300px;
  z-index: 1000;
  font-size: 20px;
  background-color: #02ad90;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
