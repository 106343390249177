export default {
	//繁体
	header:{
		menu:['首頁','交易','首發交易','質押生息','合約'],
		assets:'總資產',
		actions:['更改登錄密碼','設置交易密碼','退出','實名認證','充幣記錄','提幣記錄','交易記錄']
	},
	footer:{
		text:'全球優質數字資產',
		menu:['關於我們','用戶協議','條款協議','隱私條款']
	},
	common:{
		confirm:'確定',
		cancel:'取消',
		copy:'複製',
		copysuccess:'複製成功！',
		time:'時間'
	},
	authentication:{
		pagename:'實名認證',
		entername:'請輸入您的姓名',
		enteridcard:'請輸入您的身份證',
	},
	topUpsorder:{
		status:['回調中','充值中','成功','失敗']
	},
	index:{
		trade:'去交易',
		bantitle:'值得用戶信賴的加密貨幣交易平台',
		bantext:'竭力承諾以嚴格協議和行業領先的技術措施為用戶安全保駕護航',
		marketqs:'市場趨勢',
		pro:'產品和服務',
		protext:'為全球數百萬用戶提供安全可靠的數字資產交易和資產管理服務，助力區塊鏈經濟演進，重塑行業新生態格局',
		pro1title:'全球佈局',
		pro1text:'在全球多個國家都建立了當地的服務中心，將多種商業形式融為一體',
		pro2title:'系統安全',
		pro2text:'專業的分佈式架構和防DoS攻擊系統，冷熱錢包分離，用戶資產多維保護',
		pro3title:'流暢的交易體驗',
		pro3text:'採用高效的撮合交易引擎，確保交易體驗順暢，每秒可處理數百萬筆訂單，並為2000多萬在線用戶的平穩運行支付費用'
	},
	newTradeIndex:{
		status:['待開始','進行中','已結束'],
		buy:'訂購',
		issue:'發行量',
		bi:'幣種',
		begintime:'開始時間',
		endtime:'結束時間',
		buynumber:'訂購數量',
		enternumber:'請輸入訂購數量'
	},
	pledge:{
		record:'質押記錄',
		pledge:'質押',
		min:'最小質押數量',
		day:'質押週期',
		rate:'每日利息',
		num:'質押數量',
		enternum:'請輸入質押數量',
		historytable:['訂單編號','幣種','質押數量','總利息','鎖倉時間','到期時間','狀態'],
		historystatus:['持有','完成','已結束']
	},
	contract:{
		sj:'市價',
		xj:'限價',
		bibi:'幣幣帳戶',
		zy:'止盈',
		zs:'止損',
		buy:'買漲',
		sell:'賣空',
		set:'設定',
		yjpc:'一鍵平倉',
		surepc:'確定平倉嗎?',
		yjpcList:['全部平倉','只平多單','只平空單'],
		setzyzs:'設定止盈/止損',
		nobanlance:'餘額不足'
	},
	trade:{
		name:'名稱',
		newprice:'最新價',
		range:'漲跌幅',
		biaccount:'幣幣帳戶',
		risk:'風險率',
		risktip:'風險率是衡量賬戶風險的指標，計算公式為：持倉保證金✖️30%/賬戶淨值儅風險率為100%時，將會對賬戶中所有持倉單以市場價格進行強行平倉',
		canuse:'可用保證金',
		nouse:'未實現盈虧',
		ccmoney:'持倉保證金',
		djmoney:'凍結保證金',
		tradeing:'交易中',
		highprice:'最高價',
		lowprice:'最低價',
		volnum:'交易量',
		kcprice:'開倉價',
		kcplaceholder:'以市場上最優價格開倉',
		num:'交易手數',
		enternum:'請輸入數量',
		banlance:'可用',
		buy:'買入',
		sell:'賣出',
		index:'序號',
		price:'價格',
		record:'歷史交易記錄',
		table:['幣種','買賣方向','結算價格','數量','買入時間','賣出時間','盈虧','買入價位','最新價位','杠杆倍率'],
		type:'方向',
		tradesuccess:'下單成功',
		doprice:'執行價',
		income:'收益',
		bi:'貨幣',
		continue:'繼續下單',
		pc:'平倉',
		action:'操作',
		jye:'交易額',
		sjjy:'市價交易',
		xjjy:'限價交易',
		surepc:'確定平倉嗎？'
	},
	tradeRecord:{
		yl:'盈利',
		kcj:'開倉價',
		zyj:'止盈價',
		zhsl:'折合數量',
		dqj:'當前價',
		zsj:'止損價',
		kybzj:'可用保證金',
		bzj:'保證金',
		sxf:'手續費',
		gyf:'隔夜費',
		kcsj:'開倉時間'
	},
	assets:{
		account:'幣幣賬戶',
		asset:'總資產',
		topup:'充幣',
		withdraw:'提幣',
		table:['幣種','可用','凍結','轉換']
	},
	topup:{
		canuse:'可用餘額',
		selbi:'請選擇幣種',
		binet:'充幣網絡',
		biaddress:'充幣地址'
	},
	bringup:{
		min:'最小提幣金額',
		address:'提幣地址',
		enteraddress:'請輸入提幣地址',
		num:'提幣數量',
		enternum:'請輸入提幣數量',
		pwd:'交易密碼',
		enterpassword:'請輸入交易密碼',
		nosettip:'您還未設置交易密碼，前往設置？',
		tip:'溫馨提示',
		set:'前往設置'
	},
	password:{
		loginpwd:'登錄密碼',
		paypwd:'交易密碼',
		old:'請輸入舊密碼',
		new:'請輸入新密碼',
		sure:'確認新密碼',
		setsuccess:'密碼設置成功！'
	},
	login:{
		welcome:'歡迎登陸',
		title:'登錄',
		enterphone:'請輸入手機號',
		enterpassword:'請輸入密碼',
		loginsuccess:'登錄成功'
	},
	register:{
		nav:['手機註冊','郵箱註冊'],
		title:'註冊',
		quick:'快速註冊',
		entereamil:'請輸入郵箱',
		surepwd:'請確認密碼',
		entercode:'請輸入驗證碼',
		nosame:'兩次密碼不一致，請重新輸入!',
		registersuccess:'註冊成功,請登錄！',
		get:'獲取',
		enterpaypass:'請輸入支付密碼',
		enterinvitecode:'請輸入邀請碼'
	}
}
