export default {
	//越南语
	header:{
		menu:['Trang chủ','Giao dịch','IPOTransactions','PledgeInterest','Hợp đồng'],
		assets:'TotalAssets',
		actions:['ChangeLoginPassword','SetTransactionPassword','Exit','Xác thực tên thật','Kỷ lục nạp tiền','Hồ sơ rút tiền','Hồ sơ giao dịch']
	},
	footer:{
		text:'GlobalHighQualityDigitalAssets',
		menu:['AboutUs','UserAgreement','TermsAgreement','PrivacyTerms']
	},
	common:{
		confirm:'OK',
		cancel:'Cancel',
		copy:'Copy',
		copysuccess:'Copy thành công!',
		time:'Thời gian'
	},
	authentication:{
		pagename:'Xác minh tên thật',
		entername:'Vui lòng nhập tên của bạn',
		enteridcard:'Vui lòng nhập CMND',
	},
	topUpsorder:{
		status:['Gọi lại','Nạp tiền','Thành công','Không thành công']
	},
	index:{
		trade:'Gototrade',
		bantitle:'Nền tảng giao dịch tiền điện tử đáng tin cậy',
		bantext:'Cố gắng hết sức để đảm bảo sự an toàn của người dùng với các giao thức nghiêm ngặt và các biện pháp kỹ thuật hàng đầu trong ngành',
		marketqs:'Xu hướng thị trường',
		pro:'Sản phẩm và dịch vụ',
		protext:'Cung cấp hàng triệu người dùng trên khắp thế giới dịch vụ giao dịch và quản lý tài sản kỹ thuật số an toàn và đáng tin cậy giúp sự phát triển của nền kinh tế chuỗi khối và định hình lại mô hình sinh thái mới của ngành',
		pro1title:'toàn cầu hóa',
		pro1text:'trung tâm dịch vụ địa phương đã được thành lập ở nhiều quốc gia trên thế giới, tích hợp nhiều hình thức kinh doanh khác nhau vào Tích hợp',
		pro2title:'bảo mật hệ thống',
		pro2text:'kiến trúc phân tán chuyên nghiệp và hệ thống chống tấn công DoS,tách biệt ví nóng và ví lạnh,bảo vệ đa chiều tài sản người dùng',
		pro3title:'trải nghiệm giao dịch trơn tru',
		pro3text:'công cụ giao dịch phù hợp hiệu quả cao để đảm bảo trải nghiệm giao dịch suôn sẻ ,mỗi giâyQuá trìnhtriệusofordersvàthanh toánchosựchạy trơn trucủahơn20triệungười dùng trực tuyến'
	},
	newTradeIndex:{
		status:['Đang chờbắt đầu','Đang tiến hành','Đã đóng'],
		buy:'Đặt hàng',
		issue:'Lưu hành',
		bi:'Tiền tệ',
		begintime:'Thời gian bắt đầu',
		endtime:'Thời gian kết thúc',
		buynumber:'Số lượng đặt hàng',
		enternumber:'Vui lòng nhập số lượng đặt hàng'
	},
	pledge:{
		record:'Bản ghi thế chấp',
		pledge:'Pledge',
		min:'Số lượng tối thiểu',
		day:'Chu kỳ thế chấp',
		rate:'Lãi suất hàng ngày',
		num:'Số lượng thế chấp',
		enternum:'Vui lòng nhập số lượng cam kết',
		historytable:['Số thứ tự','Tiền tệ','Số tiền đã cam kết','tổng lãi','thời gian khóa','thời gian hết hạn','trạng thái'],
		historystatus:['đang giữ','đã hoàn thành','đã đóng']
	},
	contract:{
		sj:'Giá thị trường',
		xj:'Giới hạn',
		bibi:'Tài khoản Coin',
		zy:'Dừng lại.',
		zs:'Dừng lỗ',
		buy:'Mua tăng',
		sell:'Bán khống',
		set:'Thiết lập',
		yjpc:'Đóng vị trí bằng một cú nhấp chuột',
		surepc:'Đảm bảo đóng vị trí?',
		yjpcList:['Đóng tất cả các vị trí','Chỉ đơn giản','Chỉ trống danh sách'],
		setzyzs:'Thiết lập chốt lời/dừng lỗ',
		nobanlance:'Số dư không đủ'
	},
	trade:{
		name:'tên',
		newprice:'giá mới nhất',
		range:'tỷ lệ thay đổi',
		biaccount:'tài khoản tiền tệ',
		risk:'Tỷ lệ rủi ro',
		risktip:'Tỷ lệ rủi ro là chỉ số để đo lường rủi ro của tài khoản. Công thức tính toán là: tỷ lệ ký quỹ vị trí✖️30%/giá trị tài khoản Khi tỷ lệ rủi ro là 100%, tất cả các vị trí trong tài khoản sẽ bị buộc phải đóng ở mức giá thị trường',
		canuse:'Ký quỹ khả dụng',
		nouse:'Lợi nhuận và lỗ chưa thực hiện',
		ccmoney:'Ký quỹ vị thế',
		djmoney:'Ký quỹ cố định',
		tradeing:'Giao dịch',
		highprice:'Giá cao nhất',
		lowprice:'Giá thấp nhất',
		volnum:'Khối lượng giao dịch',
		kcprice:'Giá mở cửa',
		kcplaceholder:'Mở vị thế ở mức giá tốt nhất inthemarket',
		num:'Số lot giao dịch',
		enternum:'Vui lòng nhập số lượng',
		banlance:'có sẵn',
		buy:'mua',
		sell:'bán',
		index:'số sê-ri',
		price:'giá',
		record:'bản ghi giao dịch lịch sử',
		table:['tiền tệ','hướng mua và bán','giá thanh toán','số lượng','thời gian mua','thời gian bán','Lợi nhuận','Giá mua','Giá mới nhất','Tỷ lệ đòn bẩy'],
		type:'Hướng',
		tradesuccess:'Đơn hàng thành công',
		doprice:'Giá thực hiện',
		income:'Lợi nhuận',
		bi:'Currency',
		continue:'Continuetoplaceanorder',
		pc:'Đóng vị trí',
		action:'Hoạt động',
		jye:'Khối lượng giao dịch'
	},
	tradeRecord:{
		yl:'Lợi nhuận',
		kcj:'Giá mở',
		zyj:'Giá dừng doanh thu',
		zhsl:'Số lượng gấp',
		dqj:'Giá hiện tại',
		zsj:'Giá dừng lỗ',
		kybzj:'Ký quỹ có sẵn',
		bzj:'Ký quỹ',
		sxf:'Phí xử lý',
		gyf:'Phí qua đêm',
		kcsj:'Thời gian mở vị trí',
		sjjy:'Giao dịch thị trường',
		xjjy:'Giới hạn giao dịch',
		surepc:'Bạn có chắc chắn về việc đóng một vị trí?'
	},
	assets:{
		account:'CurrencyAccount',
		asset:'TotalAssets',
		topup:'Deposit',
		withdraw:'Withdrawal',
		table:['Currency','Available','Freeze','Conversion']
	},
	topup:{
		canuse:'AvailableBalance',
		selbi:'PleaseSelectCurrency',
		binet:'DepositNetwork',
		biaddress:'DepositAddress'
	},
	bringup:{
		min:'Số tiền rút tiền tối thiểu',
		address:'Địa chỉ rút tiền',
		enteraddress:'Vui lòng Nhập Địa chỉ Rút tiền',
		num:'Số tiền Rút',
		enternum:'Vui lòng nhập số tiền rút',
		pwd:'Mật khẩu giao dịch',
		enterpassword:'Vui lòng nhập mật khẩu giao dịch',
		nosettip:'Bạn chưa đặt mật khẩu giao dịch, gotoSettings?',
		tip:'Reminder',
		set:'GotoSettings'
	},
	password:{
		loginpwd:'Mật khẩu đăng nhập',
		paypwd:'Mật khẩu giao dịch',
		old:'Vui lòng nhập mật khẩu cũ',
		new:'Vui lòng nhập mật khẩu mới',
		sure:'Xác nhận mật khẩu mới',
		setsuccess:'Mật khẩu thành công!'
	},
	login:{
		welcome:'Chào mừng bạn đến đăng nhập vào',
		title:'Đăng nhập',
		enterphone:'Vui lòng nhập số điện thoại di động',
		enterpassword:'Vui lòng nhập mật khẩu',
		loginsuccess:'Đăng nhập thành công'
	},
	register:{
		nav:['Đăng ký điện thoại di động','Email đăng ký'],
		title:'Đăng ký',
		quick:'Đăng ký nhanh',
		entereamil:'Vui lòng nhập địa chỉ email',
		surepwd:'Vui lòng xác nhận mật khẩu',
		entercode:'Vui lòng nhập mã xác minh',
		nosame:'hai mật khẩu không nhất quán, vui lòng nhập lại!',
		registersuccess:'đăng ký thành công, vui lòng đăng nhập!',
		get:'Nhận',
		enterpaypass:'Vui lòng nhập mật khẩu thanh toán',
		enterinvitecode:'Vui lòng nhập mã mời'
	}
}
