import { createStore } from "vuex";
import { ElLoading } from "element-plus";
import { getUserinfo, getWallet, loginout, getBaseSite, home } from "../api/index.js";
import router from "../router/index.js";
const store = createStore({
  state: {
    hasLogin: false,
    userInfo: {},
    config: {},
    walletData: {},
    langList: {},
    langValue: localStorage.getItem("lang"),
    defaultUnit: localStorage.getItem('defaultUnit') || {},
  },
  mutations: {
    setConfig(state, data) {
      state.config = data;
      localStorage.setItem("config", JSON.stringify(state.config));
    },
    setUserinfo(state, data) {
      state.hasLogin = true;
      state.userInfo = data;

      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    setDefaultUnit(state, payload) {
			state.defaultUnit = payload
			localStorage.setItem('defaultUnit', JSON.stringify(payload))
		},
    setWalletdata(state, data) {
      state.walletData = data;
    },
    setLang(state, data) {
      state.langList = data;
    },
    loginout(state) {
      state.hasLogin = false;
      state.userInfo = {};
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      router.replace({
        path: "/login",
      });
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
    config: (state) => state.config,
    hasLogin: (state) => state.hasLogin,
    walletData: (state) => state.walletData,
    defaultUnit: state => state.defaultUnit,
    langValue: (state) => state.langValue,
    langList: (state) => state.langList,
  },
  actions: {
    getConfig({ commit }) {
      getBaseSite({}).then((res) => {
        commit("setConfig", res.data);
        setTimeout(() => {
          document.title = res.data.name
          let link = document.querySelector("link[rel~='icon']");

          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = res.data.ico;
        },10);
      });
    },
    getDefaultUnit({
			commit
		}) {
			home({}).then((res) => {
				commit('setDefaultUnit', res.data.unit)
			});
		},
    userLoginout({ commit }) {
      loginout({}).then(() => {
        commit("loginout");
      });
    },
    getUserinfo({ commit }) {
      const loading = ElLoading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });

      getUserinfo({}).then((res) => {
        commit("setUserinfo", res.data);
        loading.close();
      });
    },
    async getWallet({ commit }) {
      const loading = ElLoading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await getWallet();
      if (res.type == "ok") {
        commit("setWalletdata", res.message);
      }
      loading.close();
    },
  },
  modules: {},
});
export default store;
