<template>
	<div class="footer bg12">
		<div class="width1200">
			<div class="footer-box2 flex-jus">
				<div class="flex">
					<img class="logo" :src="config.logo" alt="">
					<span class="bold cfff f16 ml10">{{ config.name }}</span>
				</div>
				<div>
					<span class="f14 cursor cfff mr20" v-for="(item,index) in footList" :key="index"
						@click="menuClick(item)">{{item.name}}</span>
					<!-- <router-link :to="item.path" class="cfff f13 mr20" v-for="(item,index) in footList"
						:key="index">{{item.name}}</router-link> -->
				</div>
			</div>
			<div class="textC footer-box1">
				<p class="cfff f12">{{ config.name }} © 2023 - {{ config.name }} All rights reserved.</p>
			</div>
		</div>
	</div>
	<el-dialog :title="title" v-model="showDialog" width="60%">
		<div class="dialog-content">
			<div v-if="dialogType == '1'">
				<p>&nbsp; &nbsp; &nbsp; MBN cryptocurrency exchange is headquartered in Singapore. In addition, there
					are
					three operation centers in the United States, South Korea, and Hong Kong. The scope of services is
					vast
					and the market radiates all over the world.</p>
				<p>&nbsp; &nbsp; &nbsp; MBN has a professional, efficient and experienced blockchain technology and
					operation team with decades of experience in Internet development and services. A group of Internet
					experts with unique insights and foresight are committed to providing a safe, convenient, stable and
					low
					transaction cost platform for global cryptocurrency contract trading users. The main members of the
					team
					come from well-known companies such as Google, Amazon and Alibaba.</p>
				<p>&nbsp;</p>
				<p><strong>A. Strength</strong></p>
				<p>MBN is committed to building a safe and reliable cryptocurrency trading platform. The team has
					decades of
					experience in financial risk control. The core members graduated from prestigious universities such
					as
					Harvard University, Stanford University, University of California, Berkeley, Hong Kong University,
					Seoul
					University and Tsinghua University. MBN is headquartered in Singapore and holds dual financial
					licenses.
					The platform is stable for a long time and venture capital is guaranteed.</p>
				<p><strong>B, focus</strong></p>
				<p>MBN focuses on cryptocurrency intraday trading, contract trading, second contract trading, ICO and
					cloud
					mining. The exchange provides systematic technology and service solutions for contract transactions,
					and
					selects the world's mainstream cryptocurrencies.</p>
				<p><strong>C, smooth</strong></p>
				<p>The exchange system fully optimizes the user experience, the load multi-point shunt technology
					maximizes
					the smoothness of the system and provides multi-level servers to guarantee the transaction speed!
					The
					trading system experience satisfaction is benchmarked against the world's top trading system.</p>
				<p><strong>D, safety</strong></p>
				<p>MBN's financial-level security protects user assets, digital asset storage is intelligently separated
					from hot and cold, ERC20 digital wallets, and account encryption technologies are fully applied.</p>
				<p><strong>E, service</strong></p>
				<p>MBN has an independent and complete user service system, provides the most complete and convenient
					management system support, 7*24h quick response, and truly creates a fair, just and open data
					trading
					market</p>
				<p><strong>F, platform advantages</strong></p>
				<p>1. Features two - way trading, leveraged contracts, second contracts.</p>
				<p>2. The interface is simple and clear, easy to operate.</p>
				<p>3. The deposit and withdrawal is convenient and fast, and can be transferred in major exchanges and
					wallets.</p>
				<p>4.ICO(Initial MBN Offering): Use blockchain to combine rights and cryptocurrency to finance projects
					to
					develop, maintain, and exchange related products or services</p>
			</div>
			<div class="flex-jus" v-if="dialogType == '2'">
				<img src="../assets/img/1.jpeg" alt="" style="width: 50%;" />
				<img src="../assets/img/2.jpeg" alt="" style="width: 50%;" />
			</div>
			<div v-if="dialogType == '3'">
				<p>The MBN Global user agreement is the relevant rights and obligations stipulated by the user and the
					MBN Global platform for various services, and is contractual.</p>
				<p>By registering and using this website, the user means that he accepts and agrees to all the
					conditions and terms of the "User Agreement". Both MBN Global and the user have carefully read all
					the terms in this "User Agreement" and the legal statements and operations issued by MBN Global The
					content of the rules, this agreement and the aforementioned terms of service, legal statements and
					operating rules have been known, understood and accepted, and agreed to use them as the basis for
					determining the rights and obligations of both parties.</p>
				<p>The MBN Global "legal statement" is an essential part of this agreement. When the user accepts this
					agreement, it shall be deemed to have accepted the entire content of the MBN Global "legal
					statement". The content of this agreement includes the body of this agreement and the published or
					Various rules, statements, and instructions that may be released in the future. All rules,
					statements, and instructions are an integral part of the agreement and have the same legal effect as
					the body of the agreement.</p>
				<p>1. User service</p>
				<p>1.1 MBN Global provides online trading platform services for users to conduct encrypted digital
					currency transactions through the platform. MBN Global does not participate in the buying and
					selling of any digital currency itself as a buyer or seller.</p>
				<p>1.2 Users have the right to browse real-time digital currency market quotations and transaction
					information on MBN Global, and have the right to submit digital currency transaction instructions
					and complete digital currency transactions through the MBN Global platform.</p>
				<p>1.3 Users have the right to view their information under the platform member account in MBN Global,
					and have the right to use the functions provided by MBN Global to operate.</p>
				<p>1.4 Users have the right to participate in website activities organized by the platform in accordance
					with the activity rules published by MBN Global.</p>
				<p>1.5 Users should abide by laws, regulations, regulatory documents and policy requirements, ensure the
					legitimacy of all funds and digital currency sources in the account, and must not engage in illegal
					or other damage to the platform or the third party in MBN Global or use MBN Global services. The
					activities of tripartite rights, such as sending or receiving any information that violates laws,
					regulations, public order and good customs, or infringes on the rights and interests of others,
					sending or receiving pyramid schemes or other harmful information or speech, using or forging MBN
					Global electronics without the authorization of MBN Global Email header information, etc.</p>
				<p>1.6 Users should abide by laws and regulations, and should properly use and keep their MBN Global
					platform account and password, fund transaction password, mobile phone number bound to the
					registration time, and the security of the mobile phone verification code received by the mobile
					phone. The user is fully responsible for any operations and consequences performed using his
					platform account and password, capital password, and mobile phone verification code. When the user
					discovers that the MBN Global platform account, password or fund password, verification code is used
					by a third party without its authorization, or there are other account security issues, the MBN
					Global platform will be notified immediately and effectively, and the platform will be required to
					suspend the service MBN Global of the platform account. The MBN Global platform has the right to
					take action on the user’s request within a reasonable time, but it does not assume any
					responsibility for the losses that the user has suffered before the MBN Global platform takes
					action. The user shall not give, borrow, rent, transfer or otherwise dispose of the MBN Global
					platform account to others without the consent of the MBN Global platform.</p>
				<p>1.7 The user shall abide by the user agreement and other terms of service and operating rules
					published and updated by the MBN Global platform from time to time.</p>
				<p>Second, the rights and obligations of users</p>
				<p>2.1 The user has the right to accept the digital currency trading platform services provided by MBN
					Global in accordance with this agreement.</p>
				<p>2.2 The user has the right to terminate the use of MBN Global platform services at any time.</p>
				<p>2.3 Users have the right to withdraw the balance of funds in MBN Global at any time, but they need to
					pay the corresponding withdrawal fees to the MBN Global platform.</p>
				<p>2.4 The user is responsible for the authenticity, validity and security of the personal information
					provided during registration.</p>
				<p>2.5 When users conduct digital currency transactions on the MBN Global platform, they must not
					maliciously interfere with the normal conduct of digital currency transactions and disrupt the order
					of transactions.</p>
				<p>2.6 Users must not interfere with the normal operation of the MBN Global platform or interfere with
					other users' use of the MBN Global platform services by any technical means or other means.</p>
				<p>2.7 If users have litigation with other users due to online transactions, they must not request the
					MBN Global platform to provide relevant data through judicial or administrative channels.</p>
				<p>2.8 Users shall not maliciously slander the reputation of the MBN Global platform by fabricating
					facts or other means.</p>
				<p>Third, the rights and obligations of the MBN Global platform</p>
				<p>3.1 If the user does not have the registration qualifications stipulated in this agreement, the MBN
					Global platform has the right to refuse the user to register, and the registered user has the right
					to cancel his MBN Global platform member account. The MBN Global platform suffers losses due to
					this. The right to claim compensation from the aforementioned users or their legal representatives.
					At the same time, the MBN Global platform reserves the right to decide whether to accept user
					registration under any other circumstances.</p>
				<p>When the MBN Global platform finds that the account user is not the initial registrant of the
					account, it has the right to suspend the use of the account.</p>
				<p>3.2 When the MBN Global platform reasonably suspects that the information provided by the user is
					incorrect, false, invalid or incomplete through technical testing, manual sampling and other testing
					methods, it has the right to notify the user to correct, update the information or suspend, and
					terminate the provision of the MBN Global platform. service.</p>
				<p>3.3 The MBN Global platform has the right to correct any information displayed on the MBN Global
					platform when there are obvious errors.</p>
				<p>The platform reserves the right to modify, suspend or terminate the MBN Global platform services at
					any time. The MBN Global platform exercises the right to modify or suspend the services without
					prior notice to the user. If the MBN Global platform terminates one or more services of the MBN
					Global platform, The termination will take effect on the day when the MBN Global platform publishes
					the termination announcement on the website.</p>
				<p>3.4 The MBN Global platform shall adopt necessary technical means and management measures to ensure
					the normal operation of the MBN Global platform, and provide necessary and reliable trading
					environment and transaction services to maintain the order of digital currency transactions.</p>
				<p>3.5 If the user has not used the MBN Global platform member account and password to log in to the MBN
					Global platform for three consecutive years, the MBN Global platform has the right to cancel the
					user's MBN Global platform account. After the account is cancelled, the MBN Global platform has the
					right to open the corresponding member name to other users for registration.</p>
				<p>3.7 The MBN Global platform guarantees the safety of users' RMB funds and digital currency custody by
					strengthening technical investment and improving security precautions. It is obliged to notify users
					in advance when there are foreseeable security risks in user funds.</p>
				<p>3.8 The MBN Global platform has the right to delete all kinds of content and information on the MBN
					Global platform website that do not comply with national laws and regulations, regulatory documents
					or reports stipulated by the MBN Global platform website. The MBN Global platform does not need to
					notify in advance to exercise this right user.</p>
				<p>Four, special statement</p>
				<p>To the extent permitted by law, under any circumstances, the MBN Global platform is protected against
					maintenance of information network equipment, information network connection failures, computer,
					communications or other system failures, power failures, strikes, labor disputes, riots, and
					uprisings. , Riots, insufficient productivity or production data, fires, floods, storms, explosions,
					wars, government actions, orders from judicial administrative organs, other force majeure or
					third-party inactions caused by inability to service or delayed services, and users suffered as a
					result The loss is not liable.</p>
				<p>Five, customer service</p>
				<p>The MBN Global platform has established a professional customer service team and established a
					complete customer service system to ensure the smooth flow of user questions and complaint channels
					in terms of technology, personnel and systems, and provide users with timely troubleshooting and
					complaint feedback.</p>
				<p>Six, intellectual property</p>
				<p>6.1 All intellectual achievements contained in the MBN Global platform include but are not limited to
					website logos, databases, website design, text and graphics, software, photos, videos, music, sounds
					and the foregoing combinations, software compilation, related source codes and software applications
					The intellectual property rights of programs and scripts are owned by the MBN Global platform. Users
					shall not copy, change, copy, send or use any of the aforementioned materials or content for
					commercial purposes.</p>
				<p>6.2 All rights (including but not limited to goodwill and trademarks, logos) contained in the name of
					the MBN Global platform belong to the MBN Global platform.</p>
				<p>6.3 The user's acceptance of this agreement shall be deemed as the user's initiative to have the
					copyright of any form of information published on the MBN Global platform, including but not limited
					to: reproduction rights, distribution rights, rental rights, exhibition rights, performance rights,
					projection rights, broadcasting rights Rights, information network communication rights, filming
					rights, adaptation rights, translation rights, compilation rights and other transferable rights
					attributable to the copyright owner are exclusively transferred to the MBN Global platform for free,
					and the MBN Global platform has the right to infringe on any subject Individually file a lawsuit and
					obtain full compensation. This agreement is a written agreement stipulated in Article 25 of the
					"United Nations Copyright Law", and its validity is applicable to the content of any copyright
					law-protected works published by users on the MBN Global platform, regardless of the formation of
					the content Before signing this agreement or after signing this agreement.</p>
				<p>6.4 Users shall not illegally use the MBN Global platform or the intellectual property rights of
					others when using the MBN Global platform services.</p>
				<p>Seven, privacy policy</p>
				<p>7.1 When a user registers an MBN Global platform account or payment account, the user provides
					personal registration information according to the requirements of the MBN Global platform,
					including but not limited to identity card information.</p>
				<p>7.2 When the user uses the MBN Global platform service or visits the MBN Global platform webpage, the
					MBN Global platform automatically receives and records the server value on the user’s browser,
					including but not limited to data such as IP address and user requirements for access Web records
					of.</p>
				<p>7.3 Relevant data collected by the MBN Global platform of users' transactions on the MBN Global
					platform, including but not limited to records of bids and purchases.</p>
				<p>7.4 The personal information of other users obtained by the MBN Global platform through legal means.
				</p>
				<p>7.5 The MBN Global platform will not sell or lend the user's personal information to anyone unless
					the user's permission is obtained in advance. The MBN Global platform does not allow any third party
					to collect, edit, sell or disseminate the user's personal information by any means.</p>
				<p>7.6 The MBN Global platform keeps the obtained customer identity data and transaction information
					confidential, and must not provide customer identity data and transaction information to any unit or
					individual, unless otherwise provided by laws and regulations.</p>
				<p>Eight, anti-money laundering</p>
				<p>8.1 The MBN Global platform complies with and implements the provisions of the "Anti-Money Laundering
					Law of the People's Republic of China" to identify users, maintain a system for customer identity
					data and transaction history records, as well as a system for large and suspicious transaction
					reports.</p>
				<p>8.2 When users register and modify their real-name information, they should provide and upload
					necessary evidence such as a copy of their ID card. The MBN Global platform will identify and
					compare the ID card information provided by the user. The MBN Global platform has reasonable grounds
					to suspect that when a user registers with a false identity, it has the right to refuse to register
					or cancel the registered account.</p>
				<p>8.3 The MBN Global platform refers to the provisions of the “Measures for the Administration of
					Large-Value Transactions and Suspicious Transaction Reports of Financial Institutions” to keep
					historical records of large-value transactions and transactions suspected of money laundering. When
					regulatory agencies require records of large-value transactions and suspicious transactions, Provide
					to regulatory agencies.</p>
				<p>8.4 The MBN Global platform saves user identity information, large-value transactions, and historical
					records of suspicious transactions, assists in accordance with the law, cooperates with judicial and
					administrative law enforcement agencies in combating money laundering activities, and assists
					judicial agencies, customs, taxation and other departments to inquire in accordance with laws and
					regulations , Freezing and deducting customer deposits.</p>
				<p>8.5 According to the national anti-money laundering policy and the protection of customer assets, the
					name of the remitter must be the same as the real-name certified name.</p>
				<p>Nine. Liability for breach of contract</p>
				<p>9.1 The violation of the MBN Global platform or the user of this agreement constitutes a breach of
					contract, and the breaching party shall be liable for breach of contract to the observant party.</p>
				<p>9.2 If the MBN Global platform causes losses to the MBN Global platform due to untrue, incomplete or
					inaccurate information provided by the user, the MBN Global platform has the right to request the
					user to compensate the MBN Global platform for losses.</p>
				<p>9.3 If a user engages in illegal activities on the MBN Global platform or using the MBN Global
					platform services due to violation of laws and regulations or the provisions of this agreement, the
					MBN Global platform has the right to immediately terminate the continued provision of MBN Global
					platform services to them, and cancel them Account and demand compensation for the losses caused to
					the MBN Global platform.</p>
				<p>9.4 If User interferes with the operation of MBN Global Platform by technical means or interferes
					with the use of MBN Global Platform by other Users, MBN Global shall have the right to immediately
					cancel the account of the User on MBN Global Platform and claim compensation for the losses caused
					to MBN Global.</p>
				<p>9.5 If users maliciously slander the reputation of the MBN Global platform by fabricating facts,
					etc., the MBN Global platform has the right to request the user to publicly apologize to the MBN
					Global platform, compensate for the losses caused to the MBN Global platform, and have the right to
					terminate it Provide MBN Global platform services.</p>
				<p>Ten. Entry into force and interpretation of the agreement</p>
				<p>This agreement takes effect when the user clicks on the MBN Global platform registration page to
					agree to register and complete the registration process, and obtains the MBN Global platform account
					and password, and is binding on the MBN Global platform and users.</p>
				<p>Eleven. Modification and termination of the agreement</p>
				<p>11.1 Changes to the agreement: the MBN Global platform has the right to change the content of this
					agreement or other terms of service and operating rules published by the MBN Global platform at any
					time. When the change is made, the MBN Global platform will publish an announcement in a prominent
					place on the MBN Global platform. , The change takes effect when the announcement is released. If
					the user continues to use the services provided by the MBN Global platform, it is deemed that the
					user agrees to the content changes. If the user does not agree with the content after the change,
					the user has the right to cancel the MBN Global platform account and stop Use MBN Global platform
					services.</p>
				<p>11.2 Termination of the agreement</p>
				<p>11.2.1 The MBN Global platform has the right to cancel the user's MBN Global platform account in
					accordance with this agreement, and this agreement terminates on the date of account cancellation.
				</p>
				<p>11.2.2 The MBN Global platform has the right to terminate all MBN Global platform services in
					accordance with this agreement, and this agreement terminates on the day when all MBN Global
					platform services are terminated.</p>
				<p>11.2.3 After the termination of this agreement, the user has no right to require the MBN Global
					platform to continue to provide it with any services or perform any other obligations, including but
					not limited to requiring the MBN Global platform to retain or disclose its original MBN Global
					platform to the user Any information in the account, forward any information that has not been read
					or sent to the user or a third party.</p>
				<p>11.2.4 The termination of this agreement does not affect the observant party to the breaching party
					to pursue liability for breach of contract.</p>
				<p>&nbsp;</p>
			</div>
			<div v-if="dialogType == '4'">
				<p>("The Company") understands the importance of including customers' personal information, and will
					comply with the provisions of the "Personal Information Protection Act" ("Act"), and endeavor to
					handle in an appropriate manner in accordance with the provisions of this privacy policy ("Privacy
					Policy") And protect personal information.</p>
				<p>1. Definition</p>
				<p>In the privacy policy, personal information refers to the “personal information” defined in the first
					paragraph of Article 2 of the Act, that is, information related to living individuals. This
					information can use the name, date of birth or other information contained in the information
					(including easy association To other information, so as to identify a specific individual) in the
					description to identify a specific individual.</p>
				<p>2. Purpose of use</p>
				<p>The company uses customers’ personal information for the following purposes:</p>
				<p>Provide and improve the company's products or services;</p>
				<p>Notify the company's products, services or activities;</p>
				<p>Carry out marketing, survey or analysis to expand the scope of the company's products or services or
					improve their quality;</p>
				<p>Provide maintenance or support for the company's services;</p>
				<p>Notify the company of revisions to the terms of use, policies, etc. ("Terms") related to the services
					provided.</p>
				<p>Deal with violations of the terms of the company's services;</p>
				<p>Verify the account held by the user;</p>
				<p>Verify the transfer to the user's account; or communicate in emergency situations.</p>
				<p>Any other purpose related to the above purpose.</p>
				<p>3. Change the purpose of use</p>
				<p>The company can change the purpose of use of personal information so that the changed purpose of use
					can be reasonably regarded as related to the original purpose of use. After the purpose of use is
					changed, the company shall notify the user or publicly announce the changed purpose of use.</p>
				<p>4. Restrict use</p>
				<p>Without the consent of the relevant customer, the company shall not use personal information beyond
					the scope necessary for the realization of the purpose of use, unless permitted by the Act or other
					laws or regulations; however, this provision does not apply to the following situations:</p>
				<p>Use personal information in accordance with laws and regulations;</p>
				<p>The use of personal information is necessary to protect personal life, body or property, and it is
					difficult to obtain the consent of relevant customers;</p>
				<p>The use of personal information is necessary to improve public health or promote the physical and
					mental health of children, and it is difficult to obtain the consent of relevant customers;</p>
				<p>Or the use of personal information is necessary for the national government, local government, or
					individuals or entities entrusted to perform affairs prescribed by laws and regulations, and
					obtaining the consent of relevant customers may hinder the execution of related affairs.</p>
				<p>5. Appropriate collection</p>
				<p>The company may use appropriate means to collect personal information, but will not use deception or
					other improper means to collect personal information.</p>
				<p>6. Security Control</p>
				<p>The company fully and appropriately supervises its employees to ensure safe control of personal
					information to deal with the risk of loss, destruction, tampering or leakage.</p>
				<p>7. If the customer requests the company to correct, add or delete personal information on the grounds
					that the personal information is contrary to the facts in accordance with the provisions of the Act,
					the company should first confirm that the request was made by the person in charge, and then
					immediately make use of the purpose of use Carry out necessary investigations within the necessary
					scope, and then correct according to the investigation results, add or delete personal information,
					and notify the customer of the relevant situation (the company decides not to perform the
					correction, and the company shall notify the customer of the relevant situation when adding or
					deleting); but According to the provisions of the Act or other laws and regulations, the company is
					not obliged to perform corrections. When adding or deleting, the provisions do not apply.</p>
				<p>8. Forbidden</p>
				<p>If the customer requests the company to stop using or delete personal information on the grounds that
					the company’s processing of personal information exceeds the previously announced purpose of use or
					obtains personal information by deception or other improper means in accordance with the provisions
					of the Act, and the investigation proves that If the request is reasonable, the company should first
					confirm that the request was made by the person in charge, and then immediately stop using or delete
					personal information and notify the customer of the relevant situation; however, the company is not
					obliged to stop according to the Act or other laws and regulations. This rule does not apply when
					using or deleting personal information.</p>
				<p>9. Use information recording procedures and other technologies</p>
				<p>The services provided by the company may use information recording procedures or similar
					technologies. These technologies help the company understand the use of the company's services, etc.
					and continue to improve services. When the user wants to disable the information recording program,
					the user can change the settings of the web browser to disable the information recording program.
					Please note that after the information logging program is disabled, users will no longer be able to
					use some parts of the service.</p>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {

	} from '../api/index.js'
	import {
		mapGetters,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				showDialog: false,
				dialogType: '',
				title: '',
				footList: []
			};
		},
		computed: {
			...mapGetters([
				'tmnewTotal',
				'tbnewTotal'
			])
		},
		created() {
			this.footList = [{
					name: '关于我们',
					value: '1'
				},
				{
					name: 'MSB证书',
					value: '2'
				},
				{
					name: '服务条款',
					value: '3'
				},
				{
					name: '隐私政策说明',
					value: '4'
				}
			]
		},
		methods: {
			...mapMutations(['settbnewTotal', 'settmnewTotal']),
			menuClick(item) {
				this.dialogType = item.value
				this.title = item.name
				this.showDialog = true
			}
		},
		mounted() {
			// if (document.body.clientWidth < 1200) {
			// 	this.collapseChage();
			// }
		}
	};
</script>
<style scoped lang="scss">
	.dialog-content {
		height: 60vh;
		overflow-y: auto;
	}

	.footer {
		background-color: #111;

		.footer-box1 {
			padding: 30px 0;

		}

		.footer-box2 {
			padding: 30px 0;
			border-bottom: 1px solid #ddd;

			.logo {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}
		}
	}
</style>