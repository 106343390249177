import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions
} from 'vuex'
const baseMixin = {
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([
			'hasLogin',
			'userInfo',
			'config',
			'defaultUnit',
			'walletData',
			'langValue',
			'langList'
		])
	},
	methods: {
		openUrl(url){
			this.$router.push(url)
		},
		trimTime(val) {
			const check = time => time < 10 ? `0${time}` : time
			return `${val.getFullYear()}-${check(val.getMonth() + 1)}-${check(val.getDate())} ${check(val.getHours())}:${check(val.getMinutes())}:${check(val.getSeconds())}`
		},
		copyUrl(url) {
			var domUrl = document.createElement("input");
			domUrl.value = url;
			domUrl.id = "creatDom";
			document.body.appendChild(domUrl);
			domUrl.select(); // 选择对象
			document.execCommand("Copy"); // 执行浏览器复制命令
			let creatDom = document.getElementById("creatDom");
			creatDom.parentNode.removeChild(creatDom);
			this.$message.success(this.langList.common.copysuccess)
		},
		check(time) {
			if (time < 10) {
				time = '0' + time
			} else {
				time = time
			}
			return time;
		},
		getTime(value) {
			let date = new Date(parseInt(value) * 1000)
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
			let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
			let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
			let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
			return Y + M + D + h + m + s
		}
	}
}

export default baseMixin;
