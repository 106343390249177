export default {
	//西班牙语
	header: {
		menu: ['Página de inicio', 'Transacciones', 'Transacciones de IPO', 'Promesa de interés','Contrato'],
		assets: 'Activos totales',
		actions: ['Cambiar contraseña de inicio de sesión', 'Establecer contraseña de transacción', 'Salir',
			'Autenticación de nombre real', 'Registro de recarga de moneda', 'Registro de retiro de moneda','Registros de transacciones'
		]
	},
	footer: {
		text: 'Activos digitales de alta calidad global',
		menu: ['Acerca de nosotros', 'Acuerdo de usuario', 'Acuerdo de términos', 'Términos de privacidad']
	},
	common: {
		confirm: 'Aceptar',
		cancel: 'Cancelar',
		copy: 'Copiar',
		copysuccess: '¡Copiar con éxito!',
		time:'Tiempo'
	},
	authentication:{
		pagename:'Autenticación de nombre real',
		entername:'Por favor ingrese su nombre',
		enteridcard:'Por favor ingrese su tarjeta de identificación',
	},
	topUpsorder:{
		status:['Devolución de llamada en curso','recarga','Éxito','Fallar']
	},
	index: {
		trade: 'Gototrade',
		bantitle: 'Plataforma de comercio de criptomonedas confiable',
		bantext: 'Haga todo lo posible para garantizar la seguridad de los usuarios con protocolos estrictos y medidas técnicas líderes en la industria',
		marketqs: 'Tendencias del mercado',
		pro: 'Productos y servicios',
		protext: 'Proporcione a millones de usuarios en todo el mundo servicios de administración de activos y comercio de activos digitales seguros y confiables para ayudar a la evolución de la economía de la cadena de bloques y remodelar el nuevo patrón ecológico de la industria',
		pro1title: 'diseño global',
		pro1text: 'se han establecido centros de servicio locales en muchos países alrededor del mundo, integrando varias formas comerciales en una arquitectura integrada',
		pro2title: 'sistema de seguridad',
		pro2text: 'profesional distribuida y un sistema anti-DoSattack, separación de billeteras calientes y frías, protección multidimensional de fusores conjuntos',
		pro3title: 'experiencia de transacción fluida',
		pro3text: 'motor de transacción de coincidencia de alta eficiencia para garantizar una experiencia de transacción fluida ,cadasegundoProcesamillonesdepedidosypagaparaunaejecuciónsinproblemasdemásde20millonesdeusuariosenlínea'
	},
	newTradeIndex: {
		status: ['Iniciopendiente', 'En curso', 'Cerrado'],
		buy: 'Pedido',
		issue: 'Circulación',
		bi: 'Divisa',
		begintime: 'Hora de inicio',
		endtime: 'Hora de finalización',
		buynumber: 'Cantidad del pedido',
		enternumber: 'Ingrese la cantidad del pedido'
	},
	pledge: {
		record: 'Registro de compromiso',
		pledge: 'Promesa',
		min: 'Cantidad mínima de compromiso',
		day: 'Ciclo de compromiso',
		rate: 'Interés diario',
		num: 'Cantidad de compromiso',
		enternum: 'Ingreselacantidaddeprenda',
		historytable: ['Númerodepedido', 'Moneda', 'Cantidaddeprenda', 'interéstotal', 'tiempodebloqueo',
			'tiempodecaducidad', 'estado'
		],
		historystatus: ['retención', 'completado', 'cerrado']
	},
	contract:{
		sj:'Precio de mercado',
		xj:'Límite de precios',
		bibi:'Cuenta de moneda',
		zy:'Detener las ganancias',
		zs:'Stop loss',
		buy:'Comprar y subir',
		sell:'Venta en corto',
		set:'Configuración',
		yjpc:'Cierre de posiciones con un solo clic',
		surepc:'¿¿ está seguro de cerrar el almacén?',
		yjpcList:['Cierre total de posiciones','Solo hay muchos pedidos planos','Solo órdenes vacías'],
		setzyzs:'Establecer ganancias / pérdidas de parada',
		nobanlance:'Saldo insuficiente'
	},
	trade: {
		name: 'nombre',
		newprice: 'precio más reciente',
		range: 'tasa de cambio',
		biaccount: 'cuenta de moneda',
		risk: 'tasa de riesgo',
		risktip: 'La tasa de riesgo es un indicador para medir el riesgo de la cuenta. La fórmula de cálculo es: margen de posición ✖️ 30 %/valor neto de la cuenta Cuando la tasa de riesgo es del 100 %, todas las posiciones de la cuenta se verán obligadas a cerrar al precio de mercado',
		canuse: 'Margen disponible',
		nouse: 'Pérdidas y ganancias no realizadas',
		ccmoney: 'Margen de posición',
		djmoney: 'Margen congelado',
		tradeing: 'Negociación',
		highprice: 'Precio más alto',
		lowprice: 'Precio más bajo',
		volnum: 'Volumen de negociación',
		kcprice: 'Precio de apertura',
		kcplaceholder: 'Abierto posiciónalmejorprecioenelmercado',
		num: 'Número de comerciantes',
		enternum: 'Por favor, introduzca la cantidad',
		banlance: 'disponible',
		buy: 'comprar',
		sell: 'vender',
		index: 'número de serie',
		price: 'precio',
		record: 'registro histórico de transacciones',
		table: ['moneda', 'dirección de compra y venta', 'precio de liquidación', 'cantidad', 'tiempo de compra',
			'tiempo de venta', 'Ganancias y pérdidas','Precio de compra','El último precio','Tasa de apalancamiento'
		],
		type: 'Dirección',
		tradesuccess: 'Pedido exitoso',
		doprice: 'Precio de ejecución',
		income: 'Beneficio',
		bi: 'Divisa',
		continue: 'Continuarparahacerunpedido',
		pc:'Cierre de posiciones',
		action:'Operación',
		jye:'Volumen de transacciones',
		sjjy:'Transacciones de mercado',
		xjjy:'Transacciones de límite de precios',
		surepc:'¿¿ estás seguro de cerrar el almacén?'
	},
	tradeRecord:{
		yl:'Ganancias',
		kcj:'Precio de apertura',
		zyj:'Precio de parada de ganancias',
		zhsl:'Cantidad equivalente',
		dqj:'Precio actual',
		zsj:'Precio de parada',
		kybzj:'Margen disponible',
		bzj:'Margen',
		sxf:'Comisiones',
		gyf:'Tarifa nocturna',
		kcsj:'Hora de apertura'
	},
	assets: {
		account: 'Cuentademoneda',
		asset: 'Activostotales',
		topup: 'Depósito',
		withdraw: 'Retiro',
		table: ['Divisa', 'Disponible', 'Congelar', 'Conversión']
	},
	topup: {
		canuse: 'Saldo disponible',
		selbi: 'Por favor, seleccione Moneda',
		binet: 'Red de depósito',
		biaddress: 'Dirección de depósito'
	},
	bringup: {
		min: 'Cantidad mínima de retiro',
		address: 'Dirección de retiro',
		enteraddress: 'Ingrese la dirección de retiro',
		num: 'Importe del retiro',
		enternum: 'Ingrese el monto del retiro',
		pwd: 'Contraseña de transacción',
		enterpassword: 'Ingrese la contraseña de transacción',
		nosettip: '¿No ha establecido la contraseña de transacción, ir a Configuración?',
		tip: 'Recordatorio',
		set: 'Ir a Configuración'
	},
	password: {
		loginpwd: 'Contraseña de inicio de sesión',
		paypwd: 'Contraseña de transacción',
		old: 'Ingrese la contraseña anterior',
		new: 'Ingrese la nueva contraseña',
		sure: 'Confirme la nueva contraseña',
		setsuccess: '¡La contraseña se ha realizado correctamente!'
	},
	login: {
		welcome: 'Bienvenido alogin',
		title: 'Iniciar sesión',
		enterphone: 'Por favor, introduzca el número de teléfono móvil',
		enterpassword: 'Por favor, introduzca la contraseña',
		loginsuccess: 'Inicio de sesión exitoso'
	},
	register: {
		nav: ['​​Registro de teléfono móvil', 'Registro de correo electrónico'],
		title: 'Registro',
		quick: 'Registro rápido',
		entereamil: 'Por favor, introduzca la dirección de correo electrónico',
		surepwd: 'Por favor, confirme la contraseña',
		entercode: 'Por favor, introduzca el código de verificación',
		nosame: '¡Las dos contraseñas son incompatibles, vuelva a introducirla!',
		registersuccess: '¡El registro se ha realizado correctamente, por favor inicie sesión!',
		get:'Acceso',
		enterpaypass:'Introduzca la contraseña de pago',
		enterinvitecode:'Por favor, introduzca el Código de invitación.'
	}
}
