<template>
	<div class="header flex">
		<el-row class="header-box flex-jus">
			<el-col :span="1">

			</el-col>
			<el-col :span="11" class="flex">
				<img class="logo" :src="config.logo" alt="">
				<div class="menu-box f14 flex">
					<router-link to="/" class="cfff mr20">首页</router-link>
					<router-link to="/trade" class="cfff mr20">交易</router-link>
					<div class="user-box mr20">
						<el-dropdown>
							<span class="cfff cursor">充值</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item
										@click="openUrl('/topUps?type=0')">银行卡充值</el-dropdown-item>
									<el-dropdown-item
										@click="openUrl('/topUps?type=1')">USDT充值</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
					<div class="user-box mr20">
						<el-dropdown>
							<span class="cfff cursor">提现</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item
										@click="openUrl('/bringUp?type=0')">银行卡提现</el-dropdown-item>
									<el-dropdown-item
										@click="openUrl('/bringUp?type=1')">USDT提现</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
					<router-link to="/pledge" class="cfff mr20">定投</router-link>
					<router-link to="/notice" class="cfff mr20">公告</router-link>
				</div>
			</el-col>
			<el-col :span="11" class="right-box flex">
				<div class="mr20" v-if="!hasLogin">
					<el-button size="mini" type="text" style="color: #fff;"
						@click="openUrl('/login')">{{langList.login.title}}</el-button>
					<el-button size="mini" type="primary"
						@click="openUrl('/register')">{{langList.register.title}}</el-button>
				</div>
				<!-- <div class="lang-box">
					<el-dropdown>
						<span class="el-dropdown-link">
							<img style="border-radius: 50%;" :src="require('../assets/img/' + curLang + '.jpg')" alt="">
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item class="flex-jus" style="width: 200px;"
									v-for="(item,index) in langitemList" :key="index" @click="chooseLang(item)">
									<span>{{item.name}}</span>
									<i class="el-icon-check green" v-show="item.value == curLang"></i>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div> -->
				<span v-if="hasLogin" class="cfff f13 mr20">资产：{{ userInfo.balance }}</span>
				<div class="user-box mr20" v-if="hasLogin">
					<el-dropdown>
						<span class="el-dropdown-link cfff cursor">
							订单
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item
									@click="openUrl('/topUpsorder')">充币记录</el-dropdown-item>
								<el-dropdown-item
									@click="openUrl('/withdraworder')">提币记录</el-dropdown-item>
								<el-dropdown-item
									@click="openUrl('/investment')">定投记录</el-dropdown-item>
								<el-dropdown-item
									@click="openUrl('/tradeRecord')">交易记录</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
				
				<div class="user-box" v-if="hasLogin">
					<el-dropdown>
						<span class="el-dropdown-link cfff cursor">
							<i class="el-icon-user-solid cfff"></i>
							{{userInfo.phone}}
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<!-- <el-dropdown-item
									@click="openUrl('/tradeRecord')">{{langList.header.actions[6]}}</el-dropdown-item> -->
								<!-- <el-dropdown-item
									@click="openUrl('/topUpsorder')">充值记录</el-dropdown-item>
								<el-dropdown-item
									@click="openUrl('/withdraworder')">提现记录</el-dropdown-item>
								<el-dropdown-item
									@click="openUrl('/investment')">定投记录</el-dropdown-item> -->
								<!-- <el-dropdown-item
									@click="openUrl('/safePassword')">个人信息</el-dropdown-item> -->
								<el-dropdown-item
									@click="openUrl('/userInfo')">安全设置</el-dropdown-item>
								<!-- <el-dropdown-item
									@click="openUrl('/addCollection')">收款方式</el-dropdown-item> -->
								<el-dropdown-item @click="userLoginout">{{langList.header.actions[2]}}</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</el-col>
			<el-col :span="1">
			</el-col>
		</el-row>
	</div>
</template>
<script>
import {

	} from '../api/index.js'
	import {
		mapActions
	} from 'vuex'
	export default {
		data() {
			return {
				langitemList: [{
						name: 'English',
						value: 'en'
					},
					{
						name: '中文',
						value: 'zh'
					},
					{
						name: 'Espanhol',
						value: 'es'
					},
					{
						name: 'Français',
						value: 'fr'
					},
					{
						name: '日本語',
						value: 'ja'
					},
					{
						name: '한국어',
						value: 'ko'
					},
					{
						name: 'Русский язык',
						value: 'ru'
					},
					{
						name: 'Tiếng Việt',
						value: 'vi'
					},
					{
						name: '繁體中文',
						value: 'zh_tw'
					}
				],
				curLang: localStorage.getItem('lang'),
				menuList: []
			};
		},
		created() {
			this.menuList = [{
					name: '首页',
					path: '/'
				},
				{
					name: '交易',
					path: '/trade'
				},
				{
					name: '充值',
					path: '/topUps'
				},
				{
					name: '提现',
					path: '/bringUp'
				},
				{
					name: '定投',
					path: '/pledge'
				},
				{
					name: '公告',
					path: '/notice'
				}
			]
		},
		methods: {
			...mapActions(['userLoginout']),
			chooseLang(item) {
				localStorage.setItem('lang', item.value)
				location.reload()
			}
		},
		mounted() {
			// if (document.body.clientWidth < 1200) {
			// 	this.collapseChage();
			// }
		}
	};
</script>
<style scoped lang="scss">
	.header {
		height: 56px;
		background-color: #151617;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		.logo {
			width: 40px;
			height: 40px;
		}
		.header-box {
			width: 100%;
			margin: 0 50px;
			
			.menu-box {
				margin-left: 50px;
				line-height: 51px;

				a {
					display: inline-block;
				}

				a:hover {
					color: #fff;
				}

				.router-link-exact-active {
					color: #02ad90;
					border-bottom: 3px solid #02ad90;
				}
			}

			.right-box {
				justify-content: flex-end;
			}

			.lang-box {
				img {
					width: 30px;
					height: 30px;
					vertical-align: middle;
					cursor: pointer;
				}
			}
		}
	}
</style>