import axios from "axios";
import { ElMessage } from "element-plus";
import router from "../router/index.js";
import store from "../store/index.js";
import {
	getSignParams
} from './tools'
import {
	decrypt,
	encrypt
} from './crypto'

let url = "https://api.bitest.top";
// let url = "http://api.bitest.top";

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  baseURL: url,
  timeout: 5000,
});
// 请求超时 请求头
service.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token"); //token存储在浏览器
      token && (config.headers.token = token);
    }
    const sign_data = getSignParams(config.data)
    config.data = sign_data
		console.log(666, JSON.parse(decrypt(sign_data._data)));
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    let resdata = response.data;
    console.log(333,resdata);
    
    if (resdata.code == 1) {
      return Promise.resolve(resdata);
    } else if (resdata.code == -1) {
      store.dispatch("userLoginout");
      return Promise.resolve(resdata);
    } else {
      ElMessage.error(resdata.msg);
      return Promise.resolve(resdata);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        case 401:
          // Message.error({
          // 	 message: '请登录!'
          //  })
          // localStorage.removeItem('info');
          // setTimeout(() => {
          // 	location.reload(function(){
          // 		router.replace({
          // 		    path: '/login',// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          // 		    query: {
          // 		        redirect: router.currentRoute.fullPath
          // 		    }
          // 		});
          // 	})
          // }, 1000);
          break;
        case 403:
          ElMessage.error({
            message: "登录过期，请重新登录",
          });
          // 清除token
          localStorage.removeItem("info");
          setTimeout(() => {
            location.reload(function() {
              router.replace({
                path: "/login", // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                query: {
                  redirect: router.currentRoute.fullPath,
                },
              });
            });
          }, 1000);
          break;
        // 404请求不存在
        case 404:
          ElMessage.error("地址不存在");
          break;
        // 其他错误，直接抛出错误提示
        default:
          ElMessage.error("服务器链接失败！");
      }
      return Promise.reject(error.response);
    }
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: {
          ...params,
          // lang: localStorage.getItem("lang"),
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, {
        ...params,
        // lang: localStorage.getItem("lang"),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * 封装patch请求
 * @param url
 * @param params
 * @returns {Promise}
 */
export function patch(url, params) {
  return new Promise((resolve, reject) => {
    service.patch(url, params).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * put 请求
 * @param  url
 * @param  params
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    service.put(url, params).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
