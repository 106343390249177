<template>
	<router-view />
</template>

<script>
	import zh from './lang/zh.js'
	import en from './lang/en.js'
	import es from './lang/es.js'
	import fr from './lang/fr.js'
	import ja from './lang/ja.js'
	import ko from './lang/ko.js'
	import ru from './lang/ru.js'
	import vi from './lang/vi.js'
	import zh_tw from './lang/zh_tw.js'
	import {
		mapMutations,
		mapActions
	} from 'vuex'
	export default {
		data() {

		},
		created() {
			this.checkIsmobile()
			let langList = {
				'zh': zh,
				'en': en,
				'es': es,
				'fr': fr,
				'ja': ja,
				'ko': ko,
				'ru': ru,
				'vi': vi,
				'zh_tw': zh_tw
			}
			let lang = localStorage.getItem("lang") || 'zh'
			localStorage.setItem('lang', lang)
			this.setLang(langList[lang])
			this.getConfig()

			let token = localStorage.getItem("token")

			if (token) {
				this.getUserinfo()
				this.getDefaultUnit()
				this.$isResolve()
			} else {
				this.$isResolve()
			}
		},
		methods: {
			...mapMutations(['setLang']),
			...mapActions(['getUserinfo', 'getConfig', 'getDefaultUnit']),
			checkIsmobile() {
				let userAgent = navigator.userAgent.toLowerCase();
				if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
					location.href = 'http://bitest.top'
					return false
				}
			}
		}
	};
</script>

<style>
	@import "./assets/css/main.css";
	@import "./assets/css/color-dark.css";

	body {
		overflow: visible !important;
	}
</style>