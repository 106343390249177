export default {
	//日语
	header:{
		menu:['ホームページ','取引','IPO 取引','担保権','契約'],
		assets:'総資産',
		actions:['ログインパスワードの変更','取引パスワードの設定','終了','実名認証','チャージ記録','貨幣引出記録','トランザクション']
	},
	footer:{
		text:'グローバル高品質デジタル資産',
		menu:['私たちについて','ユーザー同意','利用規約','プライバシー規約']
	},
	common:{
		confirm:'OK',
		cancel:'キャンセル',
		copy:'コピー',
		copysuccess:'コピーが成功しました。',
		time:'時間'
	},
	authentication:{
		pagename:'確認済み',
		entername:'あなたの名前を入力してください',
		enteridcard:'IDを入力してください',
	},
	topUpsorder:{
		status:['折り返し電話中','充電中','成功','失敗']
	},
	index:{
		trade:'取引に行く',
		bantitle:'信頼できる仮想通貨取引プラットフォーム',
		bantext:'厳格なプロトコルと業界をリードする技術的対策でユーザーの安全を保証するためにあらゆる努力を払う',
		marketqs:'市場動向',
		pro:'製品とサービス',
		protext:'世界中の数百万のユーザーに安全を提供信頼性の高いデジタル資産取引および資産管理サービスは、ブロックチェーン経済の進化を支援し、業界の新しい生態学的パターンを再形成します',
		pro1title:'グローバルなレイアウト',
		pro1text:'ローカルサービスセンターが世界中の多くの国に設立され、さまざまなビジネス形態を統合されたサービスに統合しています',
		pro2title:'システムセキュリティ',
		pro2text:'プロフェッショナルな分散アーキテクチャと DoS 攻撃対策システム、ホットウォレットとコールドウォレットの分離、ユーザー資産の多次元保護',
		pro3title:'スムーズなトランザクションエクスペリエンス',
		pro3text:'スムーズなトランザクションエクスペリエンスを保証する高効率マッチングトランザクションエンジン。秒 数百万件の注文を処理し、2,000 万人を超えるオンライン ユーザーのスムーズな運営に対する支払い'
	},
	newTradeIndex:{
		status:['開始保留中','進行中','終了'],
		buy:'注文',
		issue:'循環',
		bi:'通貨',
		begintime:'開始時間',
		endtime:'終了時間',
		buynumber:'注文数量',
		enternumber:'注文数量を入力してください'
	},
	pledge:{
		record:'誓約記録',
		pledge:'誓約',
		min:'最低誓約数量',
		day:'誓約サイクル',
		rate:'日次利息',
		num:'誓約数量',
		enternum:'誓約数量を入力してください',
		historytable:['注文番号','通貨','誓約金額','合計金利','ロック時間','有効期限','ステータス'],
		historystatus:['保有','完了','クローズ']
	},
	contract:{
		sj:'市価',
		xj:'価格制限',
		bibi:'貨幣口座',
		zy:'しめきり',
		zs:'そんしつ',
		buy:'買い増し',
		sell:'空売り',
		set:'設定＃セッテイ＃',
		yjpc:'ワンクリック平倉',
		surepc:'平倉を確定しますか?',
		yjpcList:['すべての平倉','シングルのみフラット','シングルのみを空にする'],
		setzyzs:'締まり/止損の設定',
		nobanlance:'残高不足'
	},
	trade:{
		name:'名前',
		newprice:'最新価格',
		range:'変動率',
		biaccount:'通貨口座',
		risk:'リスク率',
		risktip:'リスク率は、計算式は、ポジション証拠金 ✖️30% / 口座資本 リスク率 100% の場合、口座内のすべてのポジションは市場価格で強制決済されます',
		canuse:'利用可能証拠金',
		nouse:'含み損益',
		ccmoney:'ポジション証拠金',
		djmoney:'証拠金凍結',
		tradeing:'取引',
		highprice:'高値',
		lowprice:'最低価格',
		volnum:'取引量',
		kcprice:'始値',
		kcplaceholder:'市場の最高価格でポジションをオープン',
		num:'取引手数料',
		enternum:'数量を入力してください',
		banlance:'利用可能',
		buy:'買い',
		sell:'売り',
		index:'シリアル番号',
		price:'価格',
		record:'過去の取引記録',
		table:['通貨','買いと売りの方向','決済価格','数量','買い時間','売り時間','損益','購入価格','最新価格','レバー倍率'],
		type:'方向',
		tradesuccess:'注文の成功',
		doprice:'約定価格',
		income:'利益',
		bi:'通貨',
		continue:'注文の継続',
		pc:'平倉',
		action:'操作',
		jye:'取引高',
		sjjy:'時価取引',
		xjjy:'価格制限取引',
		surepc:'平倉を確定しますか。'
	},
	tradeRecord:{
		yl:'に利益を与える',
		kcj:'オープン価格',
		zyj:'しめきりか',
		zhsl:'換算数量',
		dqj:'現在の価格',
		zsj:'ストップロス価',
		kybzj:'使用可能な保証金',
		bzj:'デポジット',
		sxf:'手数料',
		gyf:'隔夜料',
		kcsj:'開倉時間'
	},
	assets:{
		account:'通貨口座',
		asset:'総資産',
		topup:'入金',
		withdraw:'出金',
		table:['通貨','利用可能','凍結','換算']
	},
	topup:{
		canuse:'利用可能残高',
		selbi:'通貨を選択してください',
		binet:'入金ネットワーク',
		biaddress:'入金アドレス'
	},
	bringup:{
		min:'最低出金金額',
		address:'出金アドレス',
		enteraddress:'出金アドレスを入力してください',
		num:'出金金額',
		enternum:'出金金額を入力してください',
		pwd:'取引パスワード',
		enterpassword:'取引パスワードを入力してください',
		nosettip:'取引パスワードを設定していません。[設定] に移動しますか?',
		tip:'リマインダー',
		set:'設定に移動'
	},
	password:{
		loginpwd:'ログインパスワード',
		paypwd:'取引パスワード',
		old:'古いパスワードを入力してください',
		new:'新しいパスワードを入力してください',
		sure:'新しいパスワードを確認してください',
		setsuccess:'パスワードは正常に設定されました。'
	},
	login:{
		welcome:'ログインへようこそ',
		title:'ログイン',
		enterphone:'携帯電話番号を入力してください',
		enterpassword:'パスワードを入力してください',
		loginsuccess:'ログイン成功'
	},
	register:{
		nav:['携帯電話登録','メール登録'],
		title:'登録',
		quick:'クイック登録',
		entereamil:'メールアドレスを入力してください',
		surepwd:'パスワードを確認してください',
		entercode:'確認コードを入力してください',
		nosame:'2 つのパスワードが一致しません。再入力してください!',
		registersuccess:'登録が成功しました。ログインしてください!',
		get:'取得',
		enterpaypass:'支払いパスワードを入力してください',
		enterinvitecode:'招待コードを入力してください'
	}
}
