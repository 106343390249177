import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import store from '../store'
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			{
				path: "/",
				name: "index",
				meta: {
					title: '首页'
				},
				component: () => import("../views/index.vue")
			},
			{
				path: "/newTradeIndex",
				name: "newTradeIndex",
				meta: {
					title: '首发交易'
				},
				component: () => import("../views/newTradeIndex.vue")
			},
			{
				path: "/newTradeIndexInfo",
				name: "newTradeIndexInfo",
				meta: {
					title: '首发交易'
				},
				component: () => import("../views/newTradeIndexInfo.vue")
			},
			{
				path: "/pledge",
				name: "pledge",
				meta: {
					title: '质押生息'
				},
				component: () => import("../views/pledge.vue")
			},
			{
				path: "/pledge-history",
				name: "pledge-history",
				meta: {
					title: '质押记录'
				},
				component: () => import("../views/pledge-history.vue")
			},
			{
				path: "/agreetext",
				name: "agreetext",
				meta: {
					title: '详情'
				},
				component: () => import("../views/agreetext.vue")
			},
			{
				path: "/assets",
				name: "assets",
				meta: {
					title: '资产'
				},
				component: () => import("../views/assets.vue")
			},
			{
				path: "/topUpsorder",
				name: "topUpsorder",
				meta: {
					title: '充值记录'
				},
				component: () => import("../views/topUpsorder.vue")
			},
			{
				path: "/investment",
				name: "investment",
				meta: {
					title: '定投记录'
				},
				component: () => import("../views/investment.vue")
			},
			{
				path: "/notice",
				name: "notice",
				meta: {
					title: '公告'
				},
				component: () => import("../views/notice.vue")
			},
			{
				path: "/addCollection",
				name: "addCollection",
				meta: {
					title: '收款方式'
				},
				component: () => import("../views/addCollection.vue")
			},
			{
				path: "/withdraworder",
				name: "withdraworder",
				meta: {
					title: '提现记录'
				},
				component: () => import("../views/withdraworder.vue")
			},
			{
				path: "/tradeRecord",
				name: "tradeRecord",
				meta: {
					title: '交易记录'
				},
				component: () => import("../views/tradeRecord.vue")
			},
			{
				path: "/topUps",
				name: "topUps",
				meta: {
					title: '充值'
				},
				component: () => import("../views/topUps.vue")
			},
			{
				path: "/bringUp",
				name: "bringUp",
				meta: {
					title: '提现'
				},
				component: () => import("../views/bringUp.vue")
			},
			{
				path: "/trade",
				name: "trade",
				meta: {
					title: '交易'
				},
				component: () => import("../views/trade.vue")
			},
			{
				path: "/contract",
				name: "contract",
				meta: {
					title: '合约'
				},
				component: () => import("../views/contract.vue")
			},
			{
				path: "/safePassword",
				name: "safePassword",
				meta: {
					title: '交易密码'
				},
				component: () => import("../views/safePassword.vue")
			},
			{
				path: "/loginPassword",
				name: "loginPassword",
				meta: {
					title: '登录密码'
				},
				component: () => import("../views/loginPassword.vue")
			},
			{
				path: "/userInfo",
				name: "userInfo",
				meta: {
					title: '个人信息'
				},
				component: () => import("../views/userinfo.vue")
			},
			{
				path: "/market",
				name: "market",
				meta: {
					title: '行情'
				},
				component: () => import("../views/market.vue")
			},
			{
				path: "/authentication",
				name: "authentication",
				meta: {
					title: '实名认证'
				},
				component: () => import("../views/authentication.vue")
			},
		],
	},
	{
		path: "/login",
		name: "Login",
		meta: {
			title: '登录'
		},
		component: () => import("../views/Login.vue")
	},
	{
		path: "/register",
		name: "register",
		meta: {
			title: '注册'
		},
		component: () => import("../views/register.vue")
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	next();
});
export default router;
