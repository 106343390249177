export default {
	//韩语
	header: {
		menu: ['홈페이지', '거래내역', 'IPO 거래내역', '약정이자','계약'],
		assets: '총자산',
		actions: ['로그인비밀번호 변경', '거래비밀번호 설정', '종료', '실명인증','충전 기록','동전 인출 기록','거래 기록']
	},
	footer: {
		text: '글로벌 고품질 디지털 자산',
		menu: ['회사소개', '이용약관', '이용약관', '개인 정보 보호 약관']
	},
	common: {
		confirm: '확인',
		cancel: '취소',
		copy: '복사',
		copysuccess: '성공적으로 복사되었습니다!',
		time:'시간'
	},
	authentication:{
		pagename:'실명확인',
		entername:'성함을 입력해 주세요',
		enteridcard:'신분증을 입력해 주세요',
	},
	topUpsorder:{
		status:['다시 전화','충전','성공','실패']
	},
	index: {
		trade: '거래하기',
		bantitle: '신뢰할 수 있는 암호화폐 거래 플랫폼',
		bantext: '엄격한 프로토콜과 업계를 선도하는 기술적 조치로 사용자의 안전을 보장하기 위해 최선을 다하십시오',
		marketqs: '시장 동향',
		pro: '제품 및 서비스',
		protext: '전 세계 수백만 명의 사용자에게 안전을 제공하십시오 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스는 블록체인 경제의 진화를 돕고 산업의 새로운 생태 패턴을 재구성합니다.',
		pro1title: '글로벌 레이아웃',
		pro1text: '전 세계 여러 국가에 현지 서비스 센터가 설립되어 다양한 비즈니스 형태를 통합',
		pro2title: '시스템 보안',
		pro2text: '전문적인 분산 아키텍처 및 안티 DoS 공격 시스템, 핫 월렛과 콜드 월렛의 분리, 사용자 자산의 다차원 보호',
		pro3title: '원활한 거래 경험',
		pro3text: '원활한 거래 경험을 보장하는 고효율 매칭 거래 엔진, 모든 두 번째 수백만 건의 주문을 처리하고 2,000만 명이 넘는 온라인 사용자의 원활한 운영을 위해 지불'
	},
	newTradeIndex: {
		status: ['대기 시작', '진행 중', '마감'],
		buy: '주문',
		issue: '유통',
		bi: '통화',
		begintime: '시작 시간',
		endtime: '종료 시간',
		buynumber: '주문 수량',
		enternumber: '주문수량 입력'
	},
	pledge: {
		record: '약정기록',
		pledge: '약정',
		min: '최소 약정수량',
		day: '약정주기',
		rate: '일이자',
		num: '약정수량',
		enternum: '약정수량을 입력해주세요',
		historytable: ['주문번호', '통화', '약정금액', '총액 이자', '잠금 시간', '만료 시간', '상태'],
		historystatus: ['보유', '완료', '종료']
	},
	contract:{
		sj:'시가',
		xj:'제한 가격',
		bibi:'코인 계좌',
		zy:'그치다',
		zs:'손실 방지',
		buy:'매입',
		sell:'공매',
		set:'설치',
		yjpc:'원클릭 창고 정리',
		surepc:'창고를 정리하시겠습니까?',
		yjpcList:['모두 창고 정리','여러 개만 플랫','빈 시트만'],
		setzyzs:'손익 / 손실 방지 설정',
		nobanlance:'잔액 부족'
	},
	trade: {
		name: '이름',
		newprice: '최신 가격',
		range: '환율',
		biaccount: '통화 계정',
		risk: '위험률',
		risktip: '위험률은 측정할 지표입니다. 계산식은 다음과 같습니다: 포지션 증거금 ✖️30%/계좌 순가치 위험률이 100%일 때 계정의 모든 포지션은 시장 가격으로 강제 청산됨',
		canuse: '사용 가능한 증거금',
		nouse: '미실현 이익 및 손실',
		ccmoney: '포지션 마진',
		djmoney: '마진 동결',
		tradeing: '거래',
		highprice: '최고 가격',
		lowprice: '최저 가격',
		volnum: '거래량',
		kcprice: '개시 가격',
		kcplaceholder: '시장에서 가장 좋은 가격으로 포지션 개시',
		num: '거래 건수',
		enternum: '수량을 입력하십시오.',
		banlance: '사용 가능',
		buy: '매수',
		sell: '매도',
		index: '일련 번호',
		price: '가격',
		record: '과거 거래 기록',
		table: ['통화', '매수 및 매도 방향', '정산 가격', '수량', '매수 시간', '매도 시간', '손익','매입 가격','최신 가격','레버 배율'],
		type: '방향',
		tradesuccess: '주문 성공',
		doprice: '실행 가격',
		income: '이익',
		bi: '통화',
		continue: '주문 계속',
		pc:'창고 정리',
		action:'작업',
		jye:'거래액',
		sjjy:'시가 거래',
		xjjy:'가격 제한 거래',
		surepc:'창고를 정리하시겠습니까?'
	},
	tradeRecord:{
		yl:'이익',
		kcj:'창고 개설 가격',
		zyj:'이윤 정지 가격',
		zhsl:'환산 수량',
		dqj:'현재 가격',
		zsj:'손실 방지 가격',
		kybzj:'가용 보증금',
		bzj:'보증금',
		sxf:'수수료',
		gyf:'하룻밤 요금',
		kcsj:'창고 개설 시간'
	},
	assets: {
		account: '통화 계정',
		asset: '총 자산',
		topup: '입금',
		withdraw: '출금',
		table: ['통화', '사용 가능', '동결', '전환']
	},
	topup: {
		canuse: '사용 가능 잔액',
		selbi: '통화 선택',
		binet: '입금 네트워크',
		biaddress: '입금 주소'
	},
	bringup: {
		min: '최소 출금 금액',
		address: '출금 주소',
		enteraddress: '출금 주소를 입력하세요',
		num: '출금 금액',
		enternum: '출금 금액을 입력하세요',
		pwd: '거래 비밀번호',
		enterpassword: '입력하세요 거래 비밀번호',
		nosettip: '거래 비밀번호를 설정하지 않았습니다. 설정으로 이동하시겠습니까?',
		tip: '알림',
		set: '설정으로 이동'
	},
	password: {
		loginpwd: '로그인 암호',
		paypwd: '거래 암호',
		old: '이전 암호를 입력하십시오',
		new: '새 암호를 입력하십시오',
		sure: '새 암호를 확인하십시오',
		setsuccess: '암호가 성공적으로 설정되었습니다!'
	},
	login: {
		welcome: '로그인 환영합니다',
		title: '로그인',
		enterphone: '휴대전화 번호를 입력하세요',
		enterpassword: '비밀번호를 입력하세요',
		loginsuccess: '로그인 성공'
	},
	register: {
		nav: ['휴대전화 등록', '이메일 등록'],
		title: '등록',
		quick: '빠른 등록',
		entereamil: '이메일 주소를 입력하세요',
		surepwd: '비밀번호를 확인해주세요',
		entercode: '인증번호를 입력하세요',
		nosame: '두 개의 비밀번호가 일치하지 않으니 다시 입력해주세요',
		registersuccess: '성공적으로 등록되었습니다 로그인해주세요!',
		get:'가져오기',
		enterpaypass:'결제 비밀번호를 입력하십시오.',
		enterinvitecode:'초대 코드를 입력하십시오.'
	}
}
