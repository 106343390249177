import {post,get} from '../utils/request.js'

export const getBaseSite = params =>post('/base/site',params)
export const register = params =>post('/wicket/register',params)
export const login = params =>post('/wicket/login',params)
export const loginout = params =>post('/wicket/loginout',params)
export const getUserinfo = params =>post('/mine/index',params)
export const home = params =>post('/index/home',params)
export const recharge = params =>post('/recharge/index',params)
export const rechargeorder = params =>post('/recharge/order',params)
export const withdrawal = params =>post('/withdrawal/index',params)
export const withdrawalorder = params =>post('/withdrawal/order',params)
export const goodlists = params =>post('/good/index',params)
export const goodinfo = params =>post('/good/info',params)
export const goodorder = params =>post('/good/order',params)
export const goodlog = params =>post('/good/log',params)
export const setpaypass = params =>post('/mine/setpaypass',params)
export const setloginpass = params =>post('/mine/setpassword',params)
export const bankinfo = params =>post('/mine/bankinfo',params)
export const bankpost = params =>post('/mine/bankpost',params)
export const gonggao = params =>post('/mine/gonggao',params)
export const rechargelog = params =>post('/mine/rechargelog',params)
export const withdrawallog = params =>post('/mine/withdrawallog',params)
export const gamelists = params =>post('/game/lists',params)
export const gameinfo = params =>post('/game/info',params)
export const gamekline = params =>post('/game/kline',params)
export const gameorder = params =>post('/game/order',params)
export const gamelog = params =>post('/game/log',params)





//修改登录密码
export const forgetPwd = params =>post('/api/user/forget',params)
//修改支付密码
export const update_password = params =>post('/api/safe/update_password',params)

//钱包数据
export const getWallet = params =>post('/api/wallet/list',params)

//充币数据
export const get_in_address = params =>post('/api/wallet/get_in_address',params)
//获取币种信息
export const get_walletinfo = params =>post('/api/wallet/get_info',params)
//充币
export const withdraw = params =>post('/api/wallet/out',params)

//行情数据
export const getIndexMarket = params =>get('/api/currency/quotation_new',params)
//首页轮播
export const getBanner = params =>post('/api/news/list',params)
//新闻详情
export const getNewsdetail = params =>post('/api/news/detail',params)

//挖矿列表
export const depositconfig = params =>get('/api/lh/deposit/config',params)
//挖矿
export const deposit = params =>post('/api/lh/deposit',params)
//挖矿订单
export const depositOrder = params =>get('/api/lh/deposit/order',params)

//ICO
export const project = params =>get('/api/project',params)
//ICO下单
export const submitProject = params =>post('/api/project/order',params)
//获取时间
export const getSeconds = params =>get('/api/microtrade/seconds',params)

//下单余额
export const payable_currencies = params =>get('/api/microtrade/payable_currencies',params)


//下单
export const microtradeSubmit = params =>post('/api/microtrade/submit',params)
//交易记录
export const tradeRecond = params =>get('/api/microtrade/lists',params)
//交易记录
export const microtradeList = params =>get('/api/microtrade/lists',params)
//充币记录
export const getChargelog = params =>get('/api/chargelist/log',params)
//提币记录
export const getCashlist = params =>get('/api/cashlist/log',params)
//实名认证
export const real_name = params =>post('/api/user/real_name',params)
//短信
export const sendtelSms = params =>post('/api/sms_send_d',params)

//短信
export const getLeverdeal = params =>post('/api/lever/deal',params)
//短信
export const leverSubmit = params =>post('/api/lever/submit',params)
//短信
export const leverClose = params =>post('/api/lever/close',params)
//短信
export const getMytrade = params =>post('/api/lever/my_trade',params)
//设置
export const setStop = params =>post('/api/lever/setstop',params)
//一键平仓
export const batchClose = params =>post('/api/lever/batch_close',params)


