export default {
	//俄语
	header: {
		menu: ['Домашняя страница', 'Транзакции', 'Транзакции IPO', 'Проценты по залогу','Контракт'],
		assets: 'Всего активов',
		actions: ['Изменить пароль для входа', 'Установить пароль для транзакции', 'Выход',
			'Сертификация подлинного имени', 'Монетные записи', 'Монетные записи', 'Регистрация операций'
		]
	},
	footer: {
		text: 'Глобальные высококачественные цифровые активы',
		menu: ['О нас', 'Пользовательское соглашение', 'Условия соглашения', 'Условия конфиденциальности']
	},
	common: {
		confirm: 'OK',
		cancel: 'Отмена',
		copy: 'Копировать',
		copysuccess: 'Копировать успешно!',
		time: 'Время'
	},
	authentication: {
		pagename: 'Подтверждение настоящего имени',
		entername: 'Пожалуйста, введите ваше имя',
		enteridcard: 'Пожалуйста, введите ваше удостоверение личности',
	},
	topUpsorder: {
		status: ['Обратный вызов', 'Пополнение баланса', 'Успех', '> Ошибка']
	},
	index: {
		trade: 'Перейти к торговле',
		bantitle: 'Надежная платформа для торговли криптовалютой',
		bantext: 'Приложить все усилия, чтобы гарантировать безопасность пользователей с помощью строгих протоколов и ведущих в отрасли технических мер',
		marketqs: 'Тенденции рынка',
		pro: 'Продукты и услуги',
		protext: 'Обеспечить безопасность миллионов пользователей по всему миру и надежные услуги по торговле цифровыми активами и управлению активами помогают развитию экономики блокчейна и изменяют новую экологическую модель отрасли',
		pro1title: 'глобальная структура',
		pro1text: 'во многих странах мира созданы локальные сервисные центры, объединяющие различные бизнес-формы в Интегрированную',
		pro2title: 'безопасность системы',
		pro2text: 'профессиональная распределенная архитектура и система защиты от DoS-атак, разделение горячих и холодных кошельков, многомерная защита пользовательских активов',
		pro3title: 'бесперебойная работа транзакций',
		pro3text: 'высокоэффективный механизм согласования транзакций для обеспечения бесперебойной работы транзакций, каждый второй Обрабатывайте миллионы заказов и платите за бесперебойную работу более 20 миллионов онлайн-пользователей'
	},
	newTradeIndex: {
		status: ['Ожидание начала', 'В процессе', 'Закрыто'],
		buy: 'Заказ',
		issue: 'Тираж',
		bi: 'Валюта',
		begintime: 'Время начала',
		endtime: 'Время окончания',
		buynumber: 'Количество заказов',
		enternumber: 'Введите количество заказа'
	},
	pledge: {
		record: 'Запись залога',
		pledge: 'Залог',
		min: 'Минимальное количество залога',
		day: 'Цикл залога',
		rate: 'Ежедневный процент',
		num: 'Количество залога',
		enternum: 'Пожалуйста, введите количество залога',
		historytable: ['Номер заказа', 'Валюта', 'Сумма залога', 'Итого проценты', 'время блокировки',
			'время истечения', 'статус'
		],
		historystatus: ['удержание', 'завершено', 'закрыто']
	},
	contract:{
		sj:'Рыночная стоимость',
		xj:'Предельная цена',
		bibi:'Денежный счет',
		zy:'Ликвидация',
		zs:'Остановить потерю',
		buy:'Купить повышение',
		sell:'Продажа',
		set:'Настройка',
		yjpc:'Одноклавишный бункер',
		surepc:'Ты уверен, что Хирокура?',
		yjpcList:['Все склады','Только Пиндо','Только пустые листы'],
		setzyzs:'Установка стоп / стоп',
		nobanlance:'Недостаточный остаток'
	},
	trade: {
		name: 'имя',
		newprice: 'последняя цена',
		range: 'курс изменения',
		biaccount: 'валютный счет',
		risk: 'уровень риска',
		risktip: 'уровень риска является индикатором для измерения риск счета.Формула расчета: маржа позиции ✖️30%/эквивалент счета. При уровне риска 100% все позиции на счете будут принудительно закрыты по рыночной цене',
		canuse: 'Доступная маржа',
		nouse: 'Нереализованная прибыль и убыток',
		ccmoney: 'Маржа позиции',
		djmoney: 'Замораживание маржи',
		tradeing: 'Торговля',
		highprice: 'Самая высокая цена',
		lowprice: 'Самая низкая цена',
		volnum: 'Объем торгов',
		kcprice: 'Цена открытия',
		kcplaceholder: 'Открыть позицию по лучшей цене на рынке',
		num: 'Число участников',
		enternum: 'Введите количество',
		banlance: 'доступно',
		buy: 'покупка',
		sell: 'продажа',
		index: 'серийный номер',
		price: 'цена',
		record: 'историческая запись транзакции',
		table: ['валюта', 'направление покупки и продажи', 'расчетная цена', 'количество', 'время покупки',
			'время продажи', 'прибыли и убытки','Цена покупки','Последние цены','Удвоение рычага'
		],
		type: 'направление',
		tradesuccess: '> Успешный ордер',
		doprice: 'Цена исполнения',
		income: 'Прибыль',
		bi: 'Валюта',
		continue: 'Продолжить размещение ордера',
		pc: 'Хиракура',
		action: 'Операция',
		jye:'Сумма сделки',
		sjjy:'Рыночные сделки ',
		xjjy:'Сделки с ограниченной ценой ',
		surepc:'Ты уверен, что Хирокура?'
	},
	tradeRecord:{
		yl:'Прибыль',
		kcj:'Открытая цена ',
		zyj:'Превышение цены ',
		zhsl:'Эквивалент ',
		dqj:'Текущая цена ',
		zsj:'Стоп - цена ',
		kybzj:'Имеющаяся гарантия ',
		bzj:'Гарантия',
		sxf:'Плата за услуги ',
		gyf:'Ночные расходы',
		kcsj:'Время открытия склада'
	},
	assets: {
		account: 'Валютный счет',
		asset: 'Всего активов',
		topup: 'Депозит',
		withdraw: 'Вывод средств',
		table: ['Валюта', 'Доступно', 'Заморозить', 'Конвертация']
	},
	topup: {
		canuse: 'Доступный баланс',
		selbi: 'Выберите валюту',
		binet: 'Сеть депозита',
		biaddress: 'Адрес депозита'
	},
	bringup: {
		min: 'Минимальная сумма вывода',
		address: 'Адрес вывода',
		enteraddress: 'Пожалуйста, введите адрес вывода',
		num: 'Сумма вывода',
		enternum: 'Пожалуйста, введите сумму вывода',
		pwd: 'Пароль транзакции',
		enterpassword: 'Пожалуйста, введите пароль транзакции',
		nosettip: 'Вы не установили пароль транзакции, перейдите в Настройки?',
		tip: 'Напоминание',
		set: 'Зайдите в Настройки'
	},
	password: {
		loginpwd: 'Пароль для входа',
		paypwd: 'Пароль для транзакции',
		old: 'Пожалуйста, введите старый пароль',
		new: 'Пожалуйста, введите новый пароль',
		sure: 'Подтвердите новый пароль',
		setsuccess: 'Пароль успешно установлен!'
	},
	login: {
		welcome: 'Добро пожаловать в систему',
		title: 'Вход',
		enterphone: 'Пожалуйста, введите номер мобильного телефона',
		enterpassword: 'Пожалуйста, введите пароль',
		loginsuccess: 'Успешный вход'
	},
	register: {
		nav: ['Регистрация мобильного телефона', 'Регистрация по электронной почте'],
		title: 'Регистрация',
		quick: 'Быстрая регистрация',
		entereamil: 'Пожалуйста, введите адрес электронной почты',
		surepwd: 'Пожалуйста, подтвердите пароль',
		entercode: 'Пожалуйста, введите проверочный код',
		nosame: 'два пароля несовместимы, пожалуйста, введите еще раз',
		registersuccess: 'регистрация прошла успешно, пожалуйста, войдите!',
		get: 'Доступ',
		enterpaypass: ' Введите пароль оплаты. ',
		enterinvitecode: ' Введите код приглашения.'
	}
}
