import {createApp} from 'vue'
import App from './App.vue'
import Socket from './utils/socket.js' 
import Socketio from './utils/socket.io.js'
import router from './router'
import store from './store'
import baseMixin from './utils/commonmethods.js'
import VueParticles from 'vue-particles'
import installElementPlus from './plugins/element'
//import i18n from './lang/lang'
import './assets/css/icon.css'
const app = createApp(App);
installElementPlus(app)
var socket_api = 'wss://0005.studio';
//app.config.globalProperties._i18n = i18n;
app.config.globalProperties.$socket_api = socket_api;
app.config.globalProperties.$socket = Socket;
app.config.globalProperties.$socketio = Socketio;

app.config.globalProperties.$onLaunched = new Promise(resolve => {
    app.config.globalProperties.$isResolve = resolve;
})
app.use(store);
//app.use(i18n);
app.use(router);
app.use(VueParticles);
app.mixin(baseMixin);
app.mount('#app')

