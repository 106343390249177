export default {
	//英文
	header: {
		menu: ['Homepage', 'Transactions', 'IPO Transactions', 'Pledge Interest','Contract'],
		assets: 'Total Assets',
		actions: ['Change Login Password', 'Set Transaction Password', 'Exit', 'Real name authentication','Recharge Record','Cash withdrawal records','Transaction records']
	},
	footer: {
		text: 'Global High Quality Digital Assets',
		menu: ['About Us', 'User Agreement', 'Terms Agreement', 'Privacy Terms']
	},
	common: {
		confirm: 'OK',
		cancel: 'Cancel',
		copy: 'Copy',
		copysuccess: 'Copy successfully!',
		time:'Time'
	},
	authentication:{
		pagename:'Real name authentication',
		entername:'Please input your name',
		enteridcard:'Please input your idcard',
	},
	topUpsorder:{
		status:['Callback in progress','Recharging','Success','Fail']
	},
	index: {
		trade: 'Go to trade',
		bantitle: 'Trustworthy cryptocurrency trading platform',
		bantext: 'Make every effort to guarantee the safety of users with strict protocols and industry-leading technical measures',
		marketqs: 'Market trends',
		pro: 'Products and services',
		protext: 'Provide millions of users around the world Safe and reliable digital asset trading and asset management services help the evolution of the blockchain economy and reshape the new ecological pattern of the industry',
		pro1title: 'global layout',
		pro1text: 'local service centers have been established in many countries around the world, integrating various business forms into Integrated',
		pro2title: 'system security',
		pro2text: 'professional distributed architecture and anti-DoS attack system, separation of hot and cold wallets, multi-dimensional protection of user assets',
		pro3title: 'smooth transaction experience',
		pro3text: 'high-efficiency matching transaction engine to ensure smooth transaction experience, every second Process millions of orders and pay for smooth running of over 20 million online users'
	},
	newTradeIndex: {
		status: ['Pending start', 'In progress', 'Closed'],
		buy: 'Order',
		issue: 'Circulation',
		bi: 'Currency',
		begintime: 'Start time',
		endtime: 'End time',
		buynumber: 'Order quantity',
		enternumber: 'Please enter the order quantity'
	},
	pledge: {
		record: 'Pledge record',
		pledge: 'Pledge',
		min: 'Minimum pledge quantity',
		day: 'Pledge cycle',
		rate: 'Daily interest',
		num: 'Pledge quantity',
		enternum: 'Please enter the pledge quantity',
		historytable: ['Order number', 'Currency', 'Pledged amount', 'total interest', 'locking time',
			'expiration time', 'status'
		],
		historystatus: ['holding', 'completed', 'closed']
	},
	contract:{
		sj:'Market price',
		xj:'Price limit',
		bibi:'Coin account',
		zy:'Stop surplus',
		zs:'Stop loss',
		buy:'Buying up',
		sell:'Short selling',
		set:'Set up',
		yjpc:'One click closing position',
		surepc:'Are you sure to close the position?',
		yjpcList:['Full liquidation','Only Ping Duo Dan','Only flat air single'],
		setzyzs:'Set Stop Gain/Stop Loss',
		nobanlance:'Insufficient Balance'
	},
	trade: {
		name: 'name',
		newprice: 'latest price',
		range: 'change rate',
		biaccount: 'currency account',
		risk: 'Risk rate',
		risktip: 'The risk rate is an indicator to measure the risk of the account. The calculation formula is: position margin ✖️30%/account net value When the risk rate is 100%, all positions in the account will be forced to close at the market price',
		canuse: 'Available margin',
		nouse: 'Unrealized profit and loss',
		ccmoney: 'Position margin',
		djmoney: 'Freeze margin',
		tradeing: 'Trading',
		highprice: 'Highest price',
		lowprice: 'Lowest price',
		volnum: 'Trading volume',
		kcprice: 'Opening price',
		kcplaceholder: 'Open position at the best price in the market',
		num: 'Number of trading hands',
		enternum: 'Please enter the quantity',
		banlance: 'available',
		buy: 'buy',
		sell: 'sell',
		index: 'serial number',
		price: 'price',
		record: 'historical transaction record',
		table: ['currency', 'buy and sell direction', 'settlement price', 'quantity', 'buy time', 'sell Time',
			'Profit and Loss','Buying price','Latest price point','Leverage ratio'
		],
		type: 'Direction',
		tradesuccess: 'Successful Order',
		doprice: 'Execution Price',
		income: 'Profit',
		bi: 'Currency',
		continue: 'Continue to place an order',
		pc:'Closing position',
		action:'operation',
		jye:'a turnover',
		sjjy:'Market value trading',
		xjjy:'Limit trading',
		surepc:'Are you sure to close the position?'
	},
	tradeRecord:{
		yl:'profit',
		kcj:'Opening price',
		zyj:'Stop profit price',
		zhsl:'Equivalent quantity',
		dqj:'Current price',
		zsj:'Stop loss price',
		kybzj:'Free margin',
		bzj:'Margin',
		sxf:'Handling fees',
		gyf:'Overnight fee',
		kcsj:'Open Time'
	},
	assets: {
		account: 'Currency Account',
		asset: 'Total Assets',
		topup: 'Deposit',
		withdraw: 'Withdrawal',
		table: ['Currency', 'Available', 'Freeze', 'Conversion']
	},
	topup: {
		canuse: 'Available Balance',
		selbi: 'Please Select Currency',
		binet: 'Deposit Network',
		biaddress: 'Deposit Address'
	},
	bringup: {
		min: 'Minimum Withdrawal Amount',
		address: 'Withdrawal Address',
		enteraddress: 'Please Enter Withdrawal Address',
		num: 'Withdrawal Amount',
		enternum: 'Please enter the withdrawal amount',
		pwd: 'Transaction password',
		enterpassword: 'Please enter the transaction password',
		nosettip: 'You have not set the transaction password, go to Settings?',
		tip: 'Reminder',
		set: 'Go to Settings'
	},
	password: {
		loginpwd: 'Login Password',
		paypwd: 'Transaction Password',
		old: 'Please enter the old password',
		new: 'Please enter the new password',
		sure: 'Confirm the new password',
		setsuccess: 'The password is set successfully!'
	},
	login: {
		welcome: 'Welcome to log in',
		title: 'Login',
		enterphone: 'Please enter the mobile phone number',
		enterpassword: 'Please enter the password',
		loginsuccess: 'Login success'
	},
	register: {
		nav: ['Mobile phone registration', 'Email registration'],
		title: 'Registration',
		quick: 'Quick registration',
		entereamil: 'Please enter the email address',
		surepwd: 'Please confirm the password',
		entercode: 'Please Enter the verification code',
		nosame: 'the two passwords are inconsistent, please re-enter!',
		registersuccess: 'registration is successful, please log in!',
		get:'obtain',
		enterpaypass:'Please enter the payment password',
		enterinvitecode:'Please enter the invitation code'
	}
}
