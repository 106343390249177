export default {
	//中文
	header:{
		menu:['首页','交易','首发交易','质押生息','合约'],
		assets:'总资产',
		actions:['更改登录密码','设置交易密码','退 出','实名认证','充值记录','提现记录','交易记录']
	},
	footer:{
		text:'全球优质数字资产',
		menu:['关于我们','用户协议','条款协议','隐私条款']
	},
	common:{
		confirm:'确 定',
		cancel:'取 消',
		copy:'复制',
		copysuccess:'复制成功！',
		time:'时间'
	},
	index:{
		trade:'去交易',
		bantitle:'值得用户信赖的加密货币交易平台',
		bantext:'竭力承诺以严格协议和行业领先的技术措施为用户安全保驾护航',
		marketqs:'市场趋势',
		pro:'产品和服务',
		protext:'为全球数百万用户提供安全可靠的数字资产交易和资产管理服务，助力区块链经济演进，重塑行业新生态格局',
		pro1title:'全球布局',
		pro1text:'在全球多个国家都建立了当地的服务中心，将多种商业形式融为一体',
		pro2title:'系统安全',
		pro2text:'专业的分布式架构和防DoS攻击系统，冷热钱包分离，用户资产多维保护',
		pro3title:'流畅的交易体验',
		pro3text:'采用高效的撮合交易引擎，确保交易体验顺畅，每秒可处理数百万笔订单，并为2000多万在线用户的平稳运行支付费用'
	},
	authentication:{
		pagename:'实名认证',
		entername:'请输入您的姓名',
		enteridcard:'请输入您的身份证',
	},
	topUpsorder:{
		status: ['回调中', '充值中', '成功', '失败']
	},
	newTradeIndex:{
		status:['待开始','进行中','已结束'],
		buy:'订购',
		issue:'发行量',
		bi:'币种',
		begintime:'开始时间',
		endtime:'结束时间',
		buynumber:'订购数量',
		enternumber:'请输入订购数量'
	},
	pledge:{
		record:'质押记录',
		pledge:'质押',
		min:'最小质押数量',
		day:'质押周期',
		rate:'每日利息',
		num:'质押数量',
		enternum:'请输入质押数量',
		historytable:['订单编号','币种','质押数量','总利息','锁仓时间','到期时间','状态'],
		historystatus:['持有','完成','已结束']
	},
	contract:{
		sj:'市价',
		xj:'限价',
		bibi:'币币账户',
		zy:'止盈',
		zs:'止损',
		buy:'买涨',
		sell:'卖空',
		set:'设置',
		yjpc:'一键平仓',
		surepc:'确定平仓吗?',
		yjpcList:['全部平仓','只平多单','只平空单'],
		setzyzs:'设置止盈/止损',
		nobanlance:'余额不足'
	},
	trade:{
		name:'名称',
		newprice:'最新价',
		range:'涨跌幅',
		biaccount:'币币帐户',
		risk:'风险率',
		risktip:'風險率是衡量賬戶風險的指標，計算公式為：持倉保證金✖️30%/賬戶净值 儅風險率為100%時，將會對賬戶中所有持倉單以市場價格進行强行平倉',
		canuse:'可用保证金',
		nouse:'未实现盈亏',
		ccmoney:'持仓保证金',
		djmoney:'冻结保证金',
		tradeing:'交易中',
		highprice:'最高价',
		lowprice:'最低价',
		volnum:'交易量',
		kcprice:'开仓价',
		kcplaceholder:'以市场上最优价格开仓',
		num:'交易手数',
		enternum:'请输入数量',
		banlance:'可用',
		buy:'买入',
		sell:'卖出',
		index:'序号',
		price:'价格',
		record:'历史交易记录',
		table:['币种','买卖方向','结算价格','数量','买入时间','卖出时间','盈亏','买入价位','最新价位','杠杆倍率'],
		type:'方向',
		tradesuccess:'下单成功',
		doprice:'执行价',
		income:'收益',
		bi:'货币',
		continue:'继续下单',
		pc:'平仓',
		action:'操作',
		jye:'交易额',
		sjjy:'市价交易',
		xjjy:'限价交易',
		surepc:'确定平仓吗？'
	},
	tradeRecord:{
		yl:'盈利',
		kcj:'开仓价',
		zyj:'止盈价',
		zhsl:'折合数量',
		dqj:'当前价',
		zsj:'止损价',
		kybzj:'可用保证金',
		bzj:'保证金',
		sxf:'手续费',
		gyf:'隔夜费',
		kcsj:'开仓时间'
	},
	assets:{
		account:'币币账户',
		asset:'总资产',
		topup:'充币',
		withdraw:'提币',
		table:['币种','可用','冻结','转换']
	},
	topup:{
		canuse:'可用余额',
		selbi:'请选择币种',
		binet:'充币网络',
		biaddress:'充币地址'
	},
	bringup:{
		min:'最小提币金额',
		address:'提币地址',
		enteraddress:'请输入提币地址',
		num:'提币数量',
		enternum:'请输入提币数量',
		pwd:'交易密码',
		enterpassword:'请输入交易密码',
		nosettip:'您还未设置交易密码，前往设置？',
		tip:'温馨提示',
		set:'前往设置'
	},
	password:{
		loginpwd:'登录密码',
		paypwd:'交易密码',
		old:'请输入旧密码',
		new:'请输入新密码',
		sure:'确认新密码',
		setsuccess:'密码设置成功！'
	},
	login:{
		welcome:'欢迎登陆',
		title:'登录',
		enterphone:'请输入手机号',
		enterpassword:'请输入密码',
		loginsuccess:'登录成功'
	},
	register:{
		nav:['手机注册','邮箱注册'],
		title:'注册',
		quick:'快速注册',
		entereamil:'请输入邮箱',
		surepwd:'请确认密码',
		entercode:'请输入验证码',
		nosame:'两次密码不一致，请重新输入!',
		registersuccess:'注册成功,请登录！',
		get:'获取',
		enterpaypass:'请输入支付密码',
		enterinvitecode:'请输入邀请码'
	}
}